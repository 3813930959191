import MuiPaper from '@mui/material/Paper'


export const override = {
  // Empty
}

const Paper = ({...props}) => (
  <MuiPaper {...props} />
)

Paper.propTypes = {
  // Empty
}

export default Paper
