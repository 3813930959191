import PropTypes from 'prop-types'
import MuiIconButton from '@mui/material/IconButton'
import {makeStyles} from '@mui/styles'
import clsx from 'clsx'


export const override = {}

const useStyles = makeStyles((theme) => ({
  iconButton: {
    [theme.breakpoints.down('sm')]: {
      marginRight: theme.spacing(1),
      padding: '0',
      maxWidth: '3rem',
      maxHeight: '3rem',
    },
  },
  marginRight: {
    marginRight: theme.spacing(2),
  },
}))

const IconButton = ({marginRight, className, onClick, children, ...props}) => {
  const classes = useStyles()
  return (
    <MuiIconButton
        className={clsx(classes.iconButton, className, {
          [classes.marginRight]: marginRight,
        })}
        onClick={onClick}
        {...props}
    >
      {children}
    </MuiIconButton>
  )
}


IconButton.propTypes = {
  className: PropTypes.string,
  onClick: PropTypes.func,
  marginRight: PropTypes.bool,
  children: PropTypes.node.isRequired,
}

export default IconButton
