export const STRING = 'string'
export const TEXT = 'text'
export const INTEGER = 'integer'
export const NUMBER = 'number'
export const ENUM = 'enum'
export const ENUM_ARRAY = 'enum_array'
export const MEGASEAL_MATERIAL = 'megaseal_material'
export const MEGASEAL_SEAL = 'megaseal_seal'
export const MEGASEAL_DIMENSION = 'megaseal_dimension'

export const ALL_ATTRIBUTE_TYPES = [
  STRING,
  TEXT,
  INTEGER,
  NUMBER,
  ENUM,
  ENUM_ARRAY,
  MEGASEAL_MATERIAL,
  MEGASEAL_SEAL,
  MEGASEAL_DIMENSION,
]
