import PropTypes from 'prop-types'
import {makeStyles} from '@mui/styles'
import Paper from '@mui/material/Paper'
import clsx from 'clsx'
import BaseButton from '../BaseButton/BaseButton'


const useStyles = makeStyles((theme) => ({
  root: {
    'display': 'flex',
    'flexWrap': 'wrap',
    'padding': '2rem 2.25rem',
    'alignItems': 'center',
    'justifyContent': 'center',
    'height': '8rem',
    'width': '100%',

    [theme.breakpoints.down('sm')]: {
      height: '7rem',
      maxWidth: '15rem',
    },

    '& img': {
      width: '100%',
      height: '100%',
      opacity: ({disabled}) => disabled && '0.4',
    },

    '& > *': {
      display: 'block',
    },
  },
  elevation3: {
    '&:hover': {
      boxShadow: theme.shadows[8],
    },
  },
  button: {
    'padding': 0,

    '&:hover': {
      background: 'none',
      border: 'none',
    },
  },
}))

const PaperButton = ({children, component, to, disabled, onClick, ...props}) => {
  const classes = useStyles({disabled})

  return (
    <BaseButton
        component={component}
        to={to}
        disabled={disabled}
        className={classes.button}
        onClick={onClick}
        {...props}
    >
      <Paper
          component="span"
          className={clsx(classes.root, classes.elevation3)}
      >
        {children}
      </Paper>
    </BaseButton>
  )
}

PaperButton.propTypes = {
  to: PropTypes.string,
  disabled: PropTypes.bool,
  children: PropTypes.node.isRequired,
  component: PropTypes.object,
  onClick: PropTypes.func,
}

export default PaperButton
