import {createTheme} from '@mui/material/styles'
import * as overrides from '../components/visual/mui/overrides'
import palette from './palette'


const theme = createTheme({
  components: overrides,
  palette,
  mixins: {
    toolbar: {},
  },
  zIndex: {
    appBar: 1200,
    drawer: 1100,
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1280,
      xl: 1920,
    },
  },
})

export default theme
