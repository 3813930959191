import PropTypes from 'prop-types'
import {useField} from 'react-final-form'
import {showErrorOnChange} from 'common-fe/forms'
import {Choose, When, Otherwise} from 'babel-plugin-jsx-control-statements'
import {useEffect, useRef, useState} from 'react'
import {compact, uniqBy, isFunction, find} from 'lodash'
import {useListResource} from '../../../hooks/api'
import useDebouncedValue from '../../../hooks/useDebouncedValue'
import {StaticField, Autocomplete} from '../../visual'


export const getProperty = (option, optionText) => {
  if (!option) return undefined
  if (isFunction(optionText)) return optionText(option)
  return option[optionText]
}

export const queryDef = ({app, resource, q, filter = {}} = {}) => ({
  app,
  resource,
  query: {
    filter: {deleted: false, q: q || undefined, ...filter},
    pageSize: 10,
  },
  config: {
    suspense: false,
  },
})

const ReferenceInput = ({
  readOnly, name, label, required, helperText, fullWidth, showError = showErrorOnChange, fieldProps, multiple,
  defaultValue, app, resource, optionText, optionValue, filter, ...props
}) => {
  const {current: storedOptionText} = useRef(optionText)
  const {input: {value, onChange, ...restInput}, meta} = useField(name, fieldProps)
  const [inputValue, setInputValue] = useState(getProperty(defaultValue, storedOptionText) || '')
  const q = useDebouncedValue(inputValue, 300) || inputValue


  useEffect(() => {
    setInputValue(getProperty(defaultValue, storedOptionText) || '')
  }, [defaultValue, storedOptionText])

  const {data, isFetching} = useListResource(queryDef({
    app,
    resource,
    q,
    filter,
  }))

  // Great spot to try out pipeline operator
  const formattedOptions = compact([...(data || []), defaultValue])
    .map((item) => ({
      label: getProperty(item, storedOptionText),
      value: getProperty(item, optionValue),
    }))
  const options = uniqBy(formattedOptions, 'value')
  const currentOption = find(options, {value})

  const {isError, helperTextOrError} = showError({meta, helperText})

  return (
    <Choose>
      <When condition={!readOnly}>
        <Autocomplete
            fullWidth={fullWidth}
            options={options}
            multiple={multiple}
            loading={isFetching}
            value={value}
            onChange={onChange}
            inputValue={inputValue}
            filterOptions={(options) => options}
            onInputChange={(event, newInputValue) => {
              if (!event) return
              event.preventDefault()
              setInputValue(newInputValue)
            }}
            innerProps={{
              required,
              name,
              label,
              helperText: helperTextOrError,
              error: isError,
              inputProps: {required, ...restInput},
            }}
            {...props}
        />
      </When>
      <Otherwise>
        <StaticField label={label}>
          {currentOption?.label ?? '–'}
        </StaticField>
      </Otherwise>
    </Choose>
  )
}

ReferenceInput.defaultProps = {
  fullWidth: true,
  optionValue: 'id',
}

ReferenceInput.propTypes = {
  readOnly: PropTypes.bool,
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  required: PropTypes.bool,
  helperText: PropTypes.string,
  fullWidth: PropTypes.bool,
  showError: PropTypes.func,
  fieldProps: PropTypes.object,
  multiple: PropTypes.bool,
  defaultValue: PropTypes.object,
  app: PropTypes.string,
  resource: PropTypes.string.isRequired,
  optionText: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func,
  ]).isRequired,
  optionValue: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func,
  ]),
  filter: PropTypes.object,
}

export default ReferenceInput
