import PropTypes from 'prop-types'
import MuiTableSortLabel from '@mui/material/TableSortLabel'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'


export const override = {
  // Empty
}

const TableSortLabel = ({children, active, direction, ...props}) => (
  <MuiTableSortLabel
      hideSortIcon
      active={active}
      direction={direction}
      IconComponent={ArrowDropDownIcon}
      {...props}
  >
    {children}
  </MuiTableSortLabel>
)

TableSortLabel.propTypes = {
  active: PropTypes.bool,
  direction: PropTypes.string,
  children: PropTypes.node.isRequired,
}

export default TableSortLabel
