import {keyBy, get} from 'lodash'
import * as dimensions from '../../../../constants/megaseal/megasealDimensions'


const sealWidthFromArea = (area) => area && Math.sqrt((4 * area) / Math.PI)

const dimensionValueGetter = (dimensionsByName) => (dimension) => (
  get(dimensionsByName, [dimension, 'requiredValue'], null)
)
export const getBoxDimensions = (providedDimensions) => {
  if (!providedDimensions) return null
  const dimensionsByName = keyBy(providedDimensions, 'dimension')
  const getDimensionValue = dimensionValueGetter(dimensionsByName)
  const sealWidth =
    getDimensionValue(dimensions.NH) ||
    sealWidthFromArea(getDimensionValue(dimensions.S)) ||
    null

  const innerDiameter = dimensionsByName[dimensions.NI] && dimensionsByName[dimensions.NI].requiredValue || null

  const outerDiameter =
    getDimensionValue(dimensions.NA) ||
    dimensionsByName[dimensions.S] && (innerDiameter + sealWidth) ||
    null

  return {sealWidth, innerDiameter, outerDiameter}
}

export const validateDimensions = (value) => {
  if (!value) return
  const {innerDiameter, outerDiameter, sealWidth} = getBoxDimensions(value)
  if (!sealWidth || !innerDiameter || !outerDiameter) return 'Neúplné rozmery tesnenia'
  if (innerDiameter >= outerDiameter) return 'Vonkajší priemer musí byť väčší ako vnútorný'
}
