export const INDEX_FOLLOW = 'index_follow'
export const NO_INDEX_FOLLOW = 'no_index_follow'
export const INDEX_NO_FOLLOW = 'index_no_follow'
export const NO_INDEX_NO_FOLLOW = 'no_index_no_follow'

export const ALL_SEO_ROBOTS_TYPES = [
  INDEX_FOLLOW,
  NO_INDEX_FOLLOW,
  INDEX_NO_FOLLOW,
  NO_INDEX_NO_FOLLOW,
]
