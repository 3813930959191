import PropTypes from 'prop-types'
import {makeStyles} from '@mui/styles'


const useStyles = makeStyles((theme) => ({
  container: {
    width: '120px',
    marginRight: theme.spacing(1.5),

    [theme.breakpoints.down('sm')]: {
      width: '95px',
      marginRight: '0',
    },
  },
  logo: {
    verticalAlign: 'middle',
    width: '80%',
  },
}))

const Brand = ({src, alt, ...props}) => {
  const classes = useStyles()

  return (
    <div className={classes.container} >
      <img src={src} alt={alt} className={classes.logo} {...props} />
    </div>
  )
}

Brand.propTypes = {
  src: PropTypes.string.isRequired,
  alt: PropTypes.string.isRequired,
}

export default Brand
