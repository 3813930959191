import PropTypes from 'prop-types'
import MuiFormHelperText from '@mui/material/FormHelperText'


export const override = {}


const FormHelperText = ({children, ...props}) => (
  <MuiFormHelperText {...props}>
    {children}
  </MuiFormHelperText>
)

FormHelperText.propTypes = {
  children: PropTypes.node,
}

export default FormHelperText
