import {useNavigate} from '@reach/router'
import FindInPageIcon from '@mui/icons-material/FindInPage'
import {ErrorScreen} from '../visual/'


const NotFound = () => {
  const navigate = useNavigate()
  const handleBack = () => {
    navigate(-1)
  }

  return (
    <ErrorScreen
        Icon={FindInPageIcon}
        title="Nenájdené"
        buttonLabel="Späť"
        onClick={handleBack}
    >
      <div>Zadali ste zlú adresu URL alebo link...</div>
    </ErrorScreen>
  )
}

export default NotFound
