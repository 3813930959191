import MuiCircularProgress from '@mui/material/CircularProgress'


export const override = {
  // Empty
}

const CircularProgress = ({...props}) => (
  <MuiCircularProgress {...props} />
)

CircularProgress.propTypes = {
  // Empty
}

export default CircularProgress
