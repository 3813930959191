import PropTypes from 'prop-types'
import MuiAutocomplete from '@mui/lab/Autocomplete'
import {filter, find, includes, isArray, map} from 'lodash'
import useCombinedHandlers from '../../../../hooks/useCombinedHandlers'
import TextInput from '../../TextInput/TextInput'
import CircularProgress from '../CircularProgress'


export const override = {}

const AutocompleteInput = ({loading, params, InputProps, ...props}) => {
  const onBlur = useCombinedHandlers(params.inputProps.onBlur)
  const onFocus = useCombinedHandlers(params.inputProps.onFocus)
  return (
    <TextInput
        {...params}
        {...props}
        inputProps={{
          ...params.inputProps,
          onBlur,
          onFocus,
        }}
        InputProps={{
          ...InputProps,
          ...params.InputProps,
          endAdornment: (
            <>
              {loading && <CircularProgress size={20} />}
              {InputProps?.endAdornment}
              {params?.InputProps?.endAdornment}
            </>
          ),
        }}
    />
  )
}

AutocompleteInput.propTypes = {
  loading: PropTypes.bool,
  params: PropTypes.object,
  InputProps: PropTypes.object,
  inputProps: PropTypes.object,
}

const AutoComplete = ({
  label, multiple, options, loading, value, inputValue, onInputChange, innerProps, onChange,
  ...props
}) => {
  const format = (value) => value === undefined ? undefined : multiple
    ? filter(options, (option) => includes(value, option.value))
    : find(options, {value}) || null

  const parse = (selected) => isArray(selected)
    ? map(selected, 'value')
    : selected && selected.value

  const handleChange = (_event, value) => {
    onChange(parse(value))
  }
  return (
    <MuiAutocomplete
        options={options}
        multiple={multiple}
        loading={loading}
        value={format(value)}
        onChange={handleChange}
        inputValue={inputValue}
        onInputChange={onInputChange}
        getOptionSelected={(option, value) => option.value === value.value}
        getOptionLabel={(option) => option.label}
        renderInput={(params) => (
          <AutocompleteInput
              label={label}
              loading={loading}
              {...innerProps}
              params={params}
          />
        )}
        {...props}
    />
  )
}

AutoComplete.propTypes = {
  label: PropTypes.string,
  multiple: PropTypes.bool,
  options: PropTypes.array.isRequired,
  loading: PropTypes.bool,
  value: PropTypes.any,
  inputValue: PropTypes.string,
  onInputChange: PropTypes.func,
  innerProps: PropTypes.object,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  onFocus: PropTypes.func,
}

export default AutoComplete
