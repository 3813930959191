import {useCallback} from 'react'
import {isFunction} from 'lodash'


const useCombinedHandlers = (...handlers) => {
  return useCallback((...args) => {
    return handlers.filter(isFunction).reduce((_, handler) => handler(...args))
  }, // We check whether the functions in the array have changed, not the elements of the array
  handlers) // eslint-disable-line react-hooks/exhaustive-deps
}

export default useCombinedHandlers
