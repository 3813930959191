import PropTypes from 'prop-types'
import MuiTabs from '@mui/material/Tabs'


export const override = {
  // Empty
}

const Tabs = ({children, ...props}) => (
  <MuiTabs color="primary" {...props}>
    {children}
  </MuiTabs>
)

Tabs.propTypes = {
  children: PropTypes.node,
}

export default Tabs
