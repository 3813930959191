import MuiTab from '@mui/material/Tab'


export const override = {
  // Empty
}

const Tab = ({...props}) => (
  <MuiTab {...props} />
)

export default Tab
