import MuiSwitch from '@mui/material/Switch'


export const override = {
  // Empty
}

const Switch = ({...props}) => (
  <MuiSwitch color="primary" {...props} />
)

export default Switch
