import {Backdrop, CircularProgress} from '../visual'


const Loading = () => (
  <Backdrop open>
    <CircularProgress color="inherit" />
  </Backdrop>
)

export default Loading
