export const MEGASEAL_PRICE_PER_MINUTE = 'megaseal_price_per_minute'
export const MEGASEAL_PREPARATION_TIME = 'megaseal_preparation_time'
export const MEGASEAL_MANUFACTURE_TIMES = 'megaseal_manufacture_times'
export const MEGASEAL_WASTE_DIFFERENCE_HARD = 'megaseal_waste_difference_hard'
export const MEGASEAL_WASTE_DIFFERENCE_FLOPPY = 'megaseal_waste_difference_floppy'
export const MEGASEAL_MANUFACTURE_NOTICE = 'megaseal_manufacture_notice'
export const MEGASEAL_LABEL_NOTICE = 'megaseal_label_notice'
export const MEGASEAL_INSPECTION_NOTICE = 'megaseal_inspection_notice'
export const MEGASEAL_TRANSFER_NOTICE = 'megaseal_transfer_notice'
export const MEGASEAL_DONE_NOTICE = 'megaseal_done_notice'
export const MEGASEAL_CHUCK_SIZES = 'megaseal_chuck_sizes'

export const ALL_MEGASEAL_SYSTEM_SETTINGS = [
  MEGASEAL_PRICE_PER_MINUTE,
  MEGASEAL_PREPARATION_TIME,
  MEGASEAL_MANUFACTURE_TIMES,
  MEGASEAL_WASTE_DIFFERENCE_HARD,
  MEGASEAL_WASTE_DIFFERENCE_FLOPPY,
  MEGASEAL_MANUFACTURE_NOTICE,
  MEGASEAL_LABEL_NOTICE,
  MEGASEAL_INSPECTION_NOTICE,
  MEGASEAL_TRANSFER_NOTICE,
  MEGASEAL_DONE_NOTICE,
  MEGASEAL_CHUCK_SIZES,
]
