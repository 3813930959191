import {includes, filter, isEmpty, concat, intersection, uniq} from 'lodash'
import PropTypes from 'prop-types'
import {Checkbox} from '../../visual'


const CheckboxHeader = ({state: {selectedIds, setSelectedIds, allIds}}) => {
  const areAllSelected = intersection(selectedIds, allIds).length === allIds.length


  const handleClick = (e) => {
    e.stopPropagation()

    if (areAllSelected) {
      return setSelectedIds([])
    }

    return setSelectedIds(uniq(concat(selectedIds, allIds)))
  }


  return (
    <div>
      <Checkbox
          checked={areAllSelected}
          indeterminate={!isEmpty(selectedIds) && !areAllSelected}
          onClick={handleClick}
      />
    </div>
  )
}

CheckboxHeader.propTypes = {
  state: PropTypes.shape({
    selectedIds: PropTypes.arrayOf(PropTypes.number).isRequired,
    setSelectedIds: PropTypes.func.isRequired,
    allIds: PropTypes.array,
  }).isRequired,
}

const CheckboxCell = ({state: {selectedIds, setSelectedIds}, row}) => {
  const rowId = row.original.id
  const checked = includes(selectedIds, rowId)

  const handleChange = (e) => {
    e.stopPropagation()

    if (checked) {
      return setSelectedIds(filter(selectedIds, (id) => rowId !== id))
    }

    return setSelectedIds([...selectedIds, rowId])
  }

  return (
    <div>
      <Checkbox checked={checked} onClick={handleChange} />
    </div>
  )
}

CheckboxCell.propTypes = {
  state: PropTypes.shape({
    selectedIds: PropTypes.arrayOf(PropTypes.number).isRequired,
    setSelectedIds: PropTypes.func.isRequired,
  }).isRequired,
  row: PropTypes.object.isRequired,
}

export default (columns) => [
  {
    id: 'selection',
    Header: CheckboxHeader,
    Cell: CheckboxCell,
    checkbox: true,
  },
  ...columns,
]
