export const ACCESSORIES = 'accessories'
export const ACCESSORY_CATEGORIES = 'accessoryCategories'
export const BLANKS = 'blanks'
export const CALCULATIONS = 'calculations'
export const COMPONENTS_BLANKS = 'componentsBlanks'
export const MANUFACTURES = 'manufactures'
export const MATERIALS_CONSUMPTIONS = 'materialsConsumptions'
export const MATERIALS = 'materials'
export const MATERIAL_CATEGORIES = 'materialCategories'
export const SEALS = 'seals'
export const SEAL_CATEGORIES = 'sealCategories'
export const TRANSFERS = 'transfers'
