import PropTypes from 'prop-types'
import MuiTableBody from '@mui/material/TableBody'


export const override = {
  // Empty
}

const TableBody = ({children, ...props}) => (
  <MuiTableBody {...props}>
    {children}
  </MuiTableBody>
)

TableBody.propTypes = {
  children: PropTypes.node.isRequired,
}

export default TableBody
