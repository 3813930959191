import {clamp, filter, find, isNil, keys, sortBy} from 'lodash'
import {BLANK_SELECTION_DIFFERENCE, SEAL_WIDTH_TO_OR} from '../../../../constants/megaseal/megasealCalculations'
import * as componentTypes from '../../../../constants/megaseal/megasealComponentTypes'
import findClosest from '../../../lib/findClosest'
import {getSystemSetting} from '../../systemSettings'
import {getBoxDimensions} from './calculations'
import {MEGASEAL_WASTE_DIFFERENCE_FLOPPY, MEGASEAL_WASTE_DIFFERENCE_HARD} from './systemSettings'


/**
 * Seals with standard O-rings have outer and inner diameter larger or smaller by the size of the O-ring
 * @param seal {{hasInnerOr: boolean, hasOuterOr: boolean}}
 *  Flags indicating selected seal has O-ring on the outside/inside
 * @param dimensions {{sealWidth, innerDiameter, outerDiameter}} Current box dimensions
 */
const adjustDiameters = (seal, dimensions) => {
  const sealWidths = keys(SEAL_WIDTH_TO_OR).map(Number)
  const standardWidth = findClosest(sealWidths, dimensions.sealWidth)
  const OR = SEAL_WIDTH_TO_OR[standardWidth]
  const adjustedInnerDiameter = dimensions.innerDiameter + (seal?.hasInnerOr ? 2 * OR : 0)
  const adjustedOuterDiameter = dimensions.outerDiameter - (seal?.hasOuterOr ? 2 * OR : 0)
  return {innerDiameter: adjustedInnerDiameter, outerDiameter: adjustedOuterDiameter}
}

export const getOptions = ({values, blanks, seal}) => {
  const dimensions = getBoxDimensions(values.dimensions)
  const blanksForMaterial = filter(blanks, {materialId: values?.materialId})
  const {innerDiameter, outerDiameter} = adjustDiameters(seal, dimensions) // Adjust for omegates

  return sortBy(blanksForMaterial, [
    (blank) => (
      // Amount of waste material
      Math.abs(outerDiameter - blank.outerDiameter) + Math.abs(innerDiameter - blank.innerDiameter)
    ),
    'price',
  ])
}

export const getConsumptionLength = (component, values, wasteDifference) => {
  const {sealWidth} = getBoxDimensions(values.dimensions)
  const componentWidth = sealWidth * (component.percentage / 100)
  return (componentWidth + wasteDifference[component.type]) * values.quantity
}

export const getBlankFilters = (values, seal, systemSettings, componentId) => {
  const dimensions = getBoxDimensions(values.dimensions)
  const {innerDiameter, outerDiameter} = adjustDiameters(seal, dimensions) // Adjust for omegates
  const reserve = isNil(values.reserve) ? BLANK_SELECTION_DIFFERENCE : values.reserve
  const intersect = (outerDiameter + innerDiameter) / 2

  const wasteDifference = {
    [componentTypes.HARD]: getSystemSetting(systemSettings, MEGASEAL_WASTE_DIFFERENCE_HARD),
    [componentTypes.FLOPPY]: getSystemSetting(systemSettings, MEGASEAL_WASTE_DIFFERENCE_FLOPPY),
  }

  const component = find(seal.components, {id: componentId})
  return {
    materialId: values.materialId || undefined,
    outerDiameter: clamp(outerDiameter + reserve, intersect, outerDiameter + reserve),
    innerDiameter: clamp(innerDiameter - reserve, 0, intersect),
    quantity: values.stocked ? getConsumptionLength(component, values, wasteDifference) : undefined,
  }
}
