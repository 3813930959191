import megasealEnums from '../apps/megaseal/constants/megasealEnums'
import megashopEnums from '../apps/megashop/constants/megashopEnums'
import apps from './apps'
import scheduleFrequency from './scheduleFrequency'
import permissions from './permissions'
import order from './order'


export const APPS = 'apps'
export const SCHEDULE_FREQUENCY = 'scheduleFrequency'
export const PERMISSIONS = 'permissions'
export const ORDER = 'order'

export default {
  [APPS]: apps,
  [SCHEDULE_FREQUENCY]: scheduleFrequency,
  [PERMISSIONS]: permissions,
  [ORDER]: order,
  ...megashopEnums,
  ...megasealEnums,
}
