import PropTypes from 'prop-types'
import MuiFormControl from '@mui/material/FormControl'


export const override = {}


const FormControl = ({children, ...props}) => (
  <MuiFormControl variant="outlined" {...props}>
    {children}
  </MuiFormControl>
)

FormControl.propTypes = {
  children: PropTypes.node.isRequired,
}

export default FormControl
