export const FLEX = 'flex'
export const RICH_TEXT = 'rich_text'
export const TABS = 'tabs'
export const TAB = 'tab'
export const IMAGE = 'image'
export const FILE = 'file'
export const BANNER = 'banner'

export const ALL_CONTENT_TYPES = [
  FLEX,
  RICH_TEXT,
  TABS,
  TAB,
  IMAGE,
  FILE,
  BANNER,
]
