import {createContext, useMemo, useContext, useState} from 'react'
import PropTypes from 'prop-types'


const WidgetControlContext = createContext(false)


export const WidgetControlProvider = ({children}) => {
  const [widgetControl, setWidgetControl] = useState(false)

  const contextValue = useMemo(() => ({widgetControl, setWidgetControl}), [widgetControl, setWidgetControl])

  return (
    <WidgetControlContext.Provider value={contextValue}>
      {children}
    </WidgetControlContext.Provider>
  )
}

WidgetControlProvider.propTypes = {
  children: PropTypes.node,
}

export const useWidgetControl = () => {
  const {widgetControl, setWidgetControl} = useContext(WidgetControlContext)

  return {widgetControl, setWidgetControl}
}
