import PropTypes from 'prop-types'
import {makeStyles} from '@mui/styles'
import Paper from '../mui/Paper'


const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
    width: '100%',
    height: '100%',
    overflow: 'auto',
  },
  header: {
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'space-between',
    padding: theme.spacing(2),

    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      alignItems: 'center',
    },
  },
  title: {
    margin: `0 ${theme.spacing(2)} 0 0`,

    [theme.breakpoints.down('sm')]: {
      textAlign: 'center',
      margin: `0 0 ${theme.spacing(2)} 0`,
    },
  },
  content: {
    padding: theme.spacing(2),
  },
}))

const FormSection = ({title, actions, children, ...props}) => {
  const classes = useStyles()

  return (
    <Paper className={classes.root} {...props}>
      <div className={classes.header}>
        <h2 className={classes.title}>{title}</h2>
        {actions}
      </div>
      <div className={classes.content}>
        {children}
      </div>
    </Paper>
  )
}

FormSection.propTypes = {
  title: PropTypes.string.isRequired,
  actions: PropTypes.node,
  children: PropTypes.node.isRequired,
}

export default FormSection
