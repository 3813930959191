import PropTypes from 'prop-types'
import MuiTable from '@mui/material/Table'


export const override = {
  // Empty
}

const Table = ({children, ...props}) => (
  <MuiTable {...props}>
    {children}
  </MuiTable>
)

Table.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Table
