import {useNavigate} from '@reach/router'
import PropTypes from 'prop-types'
import generatePath from '../../../../lib/generatePath'
import ControlledDatagrid from '../../containers/Datagrid/ControlledDatagrid'


const columns = [
  {accessor: 'id', Header: 'ID'},
  {accessor: 'name', Header: 'Odoberateľ'},
  {accessor: 'address1', Header: 'Adresa 1'},
  {accessor: 'address2', Header: 'Adresa 2'},
  {accessor: 'city', Header: 'Mesto'},
  {accessor: 'zipCode', Header: 'PSČ'},
  {accessor: 'country', Header: 'Krajina'},
  {accessor: 'ico', Header: 'IČO'},
  {accessor: 'dic', Header: 'DIČ'},
  {accessor: 'icDph', Header: 'IČDPH'},
]

const ClientsTable = ({clients, total, query, setQuery, selectedIds, setSelectedIds, ...props}) => {
  const navigate = useNavigate()

  const handleRowClick = ({original: {id}}) => navigate(generatePath('/clients/:clientId', {clientId: id}))

  return (
    <>
      <ControlledDatagrid
          data={clients}
          columns={columns}
          total={total}
          query={query}
          setQuery={setQuery}
          currentpage={query?.page}
          selectedIds={selectedIds}
          setSelectedIds={setSelectedIds}
          onRowClick={handleRowClick}
          {...props}
      />
    </>
  )
}

ClientsTable.propTypes = {
  clients: PropTypes.array.isRequired,
  total: PropTypes.number,
  query: PropTypes.object,
  setQuery: PropTypes.func,
  selectedIds: PropTypes.array,
  setSelectedIds: PropTypes.func,
}

export default ClientsTable
