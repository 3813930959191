import PropTypes from 'prop-types'
import MuiListItem from '@mui/material/ListItem'


export const override = {}


const ListItem = ({children, ...props}) => (
  <MuiListItem {...props}>
    {children}
  </MuiListItem>
)

ListItem.propTypes = {
  children: PropTypes.node.isRequired,
}

export default ListItem
