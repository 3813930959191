import * as megashopOrderStates from '../../../../../constants/megashop/megashopOrderStates'


export default {
  [megashopOrderStates.NEW]: 'Nový',
  [megashopOrderStates.ACCEPTED]: 'Prijatý',
  [megashopOrderStates.SENT]: 'Odoslaný',
  [megashopOrderStates.COMPLETED]: 'Dokončený',
  [megashopOrderStates.CLOSED]: 'Kúpil inde',
}
