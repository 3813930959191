import * as megashopAttributeTypes from 'common/constants/megashop/megashopAttributeTypes'


export default {
  [megashopAttributeTypes.ENUM]: 'Výber jednej možnosti',
  [megashopAttributeTypes.ENUM_ARRAY]: 'Výber viacerých možností',
  [megashopAttributeTypes.INTEGER]: 'Celé číslo',
  [megashopAttributeTypes.NUMBER]: 'Číslo',
  [megashopAttributeTypes.STRING]: 'Krátky text',
  [megashopAttributeTypes.TEXT]: 'Dlhý text',
  [megashopAttributeTypes.MEGASEAL_MATERIAL]: '(Megaseal) Materiál',
  [megashopAttributeTypes.MEGASEAL_SEAL]: '(Megaseal) Profil',
  [megashopAttributeTypes.MEGASEAL_DIMENSION]: '(Megaseal) Rozmer',
}
