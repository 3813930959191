import PropTypes from 'prop-types'
import MuiTableContainer from '@mui/material/TableContainer'
import Paper from './Paper'


export const override = {
  // Empty
}

const TableContainer = ({children, ...props}) => (
  <MuiTableContainer component={Paper} {...props}>
    {children}
  </MuiTableContainer>
)

TableContainer.propTypes = {
  children: PropTypes.node.isRequired,
}

export default TableContainer
