export const imageMimeTypes = new Set([
  'image/jpeg',
  'image/gif',
  'image/bmp',
  'image/png',
])

export const downloadMimeTypes = new Set([
  'application/pdf',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  'application/rtf',
  'application/vnd.ms-excel',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
])

export const attachmentMimeTypes = new Set([
  'image/jpeg',
  'image/gif',
  'image/bmp',
  'image/svg+xml',
  'image/png',
  'application/pdf',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  'application/rtf',
  'application/vnd.ms-excel',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
])
