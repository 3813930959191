import PropTypes from 'prop-types'
import * as enums from '../../../constants/enums'
import enumCell from '../../containers/Datagrid/cells/enumCell'
import ControlledDatagrid from '../../containers/Datagrid/ControlledDatagrid'


const columns = [
  {accessor: 'id', Header: 'ID'},
  {accessor: 'name', Header: 'Názov'},
  {
    accessor: 'permissions',
    Header: 'Oprávnenia',
    Cell: enumCell(enums.PERMISSIONS),
  },
]

const RolesTable = ({roles, total, query, setQuery, selectedIds, setSelectedIds, setRoleId, ...props}) => {
  const handleRowClick = ({original: {id}}) => (
    setRoleId(id)
  )

  return (
    <ControlledDatagrid
        data={roles}
        columns={columns}
        total={total}
        query={query}
        setQuery={setQuery}
        currentpage={query?.page}
        selectedIds={selectedIds}
        setSelectedIds={setSelectedIds}
        onRowClick={handleRowClick}
        {...props}
    />
  )
}

RolesTable.propTypes = {
  roles: PropTypes.array.isRequired,
  total: PropTypes.number,
  query: PropTypes.object,
  setQuery: PropTypes.func,
  selectedIds: PropTypes.array,
  setSelectedIds: PropTypes.func,
  setRoleId: PropTypes.func.isRequired,
}

export default RolesTable
