import PropTypes from 'prop-types'
import AssignmentIndIcon from '@mui/icons-material/AssignmentInd'
import DashboardIcon from '@mui/icons-material/Dashboard'
import PersonIcon from '@mui/icons-material/Person'
import PowerSettingsNewIcon from '@mui/icons-material/PowerSettingsNew'
import SettingsApplicationsIcon from '@mui/icons-material/SettingsApplications'
import ImageIcon from '@mui/icons-material/Image'
import {Link} from '@reach/router'
import {If} from 'babel-plugin-jsx-control-statements'
import {lazy} from 'react'
import * as permissions from '../../constants/permissions'
import {hasPermissions} from '../common/auth'
import {useAuth, useSession} from './hooks/auth'
import Navigation from './components/visual/Navigation/Navigation'
import NavigationList from './components/visual/Navigation/NavigationList'
import NavigationItem from './components/visual/Navigation/NavigationItem'
import Dashboard from './components/screens/dashboard/Dashboard'
import UsersList from './components/screens/users/UsersList'
import UsersCreate from './components/screens/users/UsersCreate'
import UsersEdit from './components/screens/users/UsersEdit'
import RolesList from './components/screens/roles/RolesList'
import ClientsList from './components/screens/clients/ClientsList'
import ClientsCreate from './components/screens/clients/ClientsCreate'
import ClientsEdit from './components/screens/clients/ClientsEdit'
import NotFound from './components/screens/NotFound'
import ProfileEdit from './components/screens/profile/ProfileEdit'
import Screen from './components/containers/Screen/Screen'
import ThemeProvider from './theme/ThemeProvider'
import {ContentContainer} from './components/visual'
import MediaLibraryList from './components/screens/mediaLibrary/MediaLibraryList'


const SystemSettingsList = lazy(() => import(
  /* webpackChunkName: "megaseal" */ './components/screens/systemSettings/SystemSettingsList')
)
const SystemSettingsEdit = lazy(() => import(
  /* webpackChunkName: "megaseal" */ './components/screens/systemSettings/SystemSettingsEdit')
)

const Router = ({openMenu}) => {
  const {logout} = useAuth()
  const session = useSession()

  return (
    <ThemeProvider>
      <ContentContainer>
        <Navigation open={openMenu}>
          <NavigationList open={openMenu}>
            <NavigationItem component={Link} to="/" icon={<DashboardIcon />}>Prehľad</NavigationItem>
            <If condition={hasPermissions(session.permissions, [permissions.MANAGE_CLIENTS])}>
              <NavigationItem component={Link} to="/clients" icon={<AssignmentIndIcon />}>
                Odoberatelia
              </NavigationItem>
            </If>
            <If condition={hasPermissions(session.permissions, [permissions.MANAGE_USERS])}>
              <NavigationItem component={Link} to="/users" icon={<PersonIcon />}>
                Používatelia
              </NavigationItem>
            </If>
            <If condition={hasPermissions(session.permissions, [permissions.MANAGE_SYSTEM_SETTINGS])}>
              <NavigationItem component={Link} to="/systemSettings" icon={<SettingsApplicationsIcon />}>
                Systémové nastavenia
              </NavigationItem>
            </If>
            <NavigationItem component={Link} to="/mediaLibrary" icon={<ImageIcon />}>
              Obrázky
            </NavigationItem>
            <NavigationItem icon={<PowerSettingsNewIcon />} onClick={logout}>Odhlásiť sa</NavigationItem>
          </NavigationList>
        </Navigation>
        <Screen>
          <Dashboard path="/" />
          <If condition={hasPermissions(session.permissions, [permissions.MANAGE_USERS])}>
            <UsersList path="/users" />
            <UsersCreate path="/users/create" />
            <UsersEdit path="/users/:userId" />
            <RolesList path="/roles" />
          </If>
          <If condition={hasPermissions(session.permissions, [permissions.MANAGE_CLIENTS])}>
            <ClientsList path="/clients" />
            <ClientsCreate path="/clients/create" />
            <ClientsEdit path="/clients/:clientId" />
          </If>
          <If condition={hasPermissions(session.permissions, [permissions.MANAGE_SYSTEM_SETTINGS])}>
            <SystemSettingsList path="/systemSettings" />
            <SystemSettingsEdit path="/systemSettings/:systemSettingId" />
          </If>
          <MediaLibraryList path="/mediaLibrary" />
          <ProfileEdit path="/profile" />
          <NotFound default />
        </Screen>
      </ContentContainer>
    </ThemeProvider>
  )
}

Router.propTypes = {
  openMenu: PropTypes.bool,
}

export default Router
