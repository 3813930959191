import PropTypes from 'prop-types'
import MuiTableHead from '@mui/material/TableHead'


export const override = {
  // Empty
}

const TableHead = ({children, ...props}) => (
  <MuiTableHead {...props}>
    {children}
  </MuiTableHead>
)

TableHead.propTypes = {
  children: PropTypes.node.isRequired,
}

export default TableHead
