import {forwardRef} from 'react'
import {If} from 'babel-plugin-jsx-control-statements'
import PropTypes from 'prop-types'
import MuiMenuItem from '@mui/material/MenuItem'


export const override = {
  styleOverrides: {
    root: {
      'paddingTop': '12px',
      'paddingRight': '16px',
      'paddingBottom': '12px',
      'paddingLeft': '16px',

      '& .MuiSvgIcon-root': {
        marginRight: '16px',
      },
    },
  },
}

const MenuItem = forwardRef(({icon, children, ...props}, ref) => (
  <MuiMenuItem ref={ref} {...props}>
    <If condition={icon}>
      {icon}
    </If>
    {children}
  </MuiMenuItem>
))

MenuItem.propTypes = {
  icon: PropTypes.node,
  children: PropTypes.node.isRequired,
}

MenuItem.displayName = 'MenuItem'

export default MenuItem
