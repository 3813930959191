import PropTypes from 'prop-types'
import MuiMuiListItemText from '@mui/material/ListItemText'


export const override = {
  styleOverrides: {
    primary: {
      display: 'flex',
      justifyContent: 'space-between',
    },
  },
}


const MuiListItemText = ({children, ...props}) => (
  <MuiMuiListItemText {...props}>
    {children}
  </MuiMuiListItemText>
)

MuiListItemText.propTypes = {
  children: PropTypes.node.isRequired,
}

export default MuiListItemText
