import PropTypes from 'prop-types'
import {ThemeProvider as MuiThemeProvider} from '@mui/material/styles'
import {LocalizationProvider} from '@mui/x-date-pickers'
import {AdapterDateFns} from '@mui/x-date-pickers/AdapterDateFns'
import CssBaseline from '@mui/material/CssBaseline'
import theme from './theme'


const ThemeProvider = ({children}) => (
  <MuiThemeProvider theme={theme}>
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <CssBaseline />
      {children}
    </LocalizationProvider>
  </MuiThemeProvider>
)

ThemeProvider.propTypes = {
  children: PropTypes.node.isRequired,
}

export default ThemeProvider
