import {isObject, isArray, isString, last} from 'lodash'


export default (...args) => {
  const options = args.length > 1 && isObject(last(args)) ? last(args) : {}
  let query = args.map((arg) => {
    if (isArray(arg)) {
      return `[${arg.join(', ')}]`
    } else if (isString(arg)) {
      return arg
    }
    return ''
  }).join('.')
  if (options.modifier) query += options.modifier
  return query
}
