export default {
  common: {
    black: 'black',
    white: 'white',
    neutral: '#E4E7EB',
    muted: '#9EA0A4',
  },
  primary: {
    main: '#5f7c8b',
  },
  secondary: {
    main: '#f44336',
  },
  success: {
    main: '#45B880',
  },
  info: {
    main: '#1070CA',
  },
  warning: {
    main: '#FFB822',
  },
  text: {
    primary: '#12161B',
    secondary: '#66788A',
    disabled: '#899cb0',
    placeholder: '#4f5c69',
  },
  error: {
    main: '#f44336',
  },
  background: {
    default: '#f8fafc',
    paper: 'white',
    dark: '#1d1f21',
  },
  border: '#DFE3E8',
  divider: '#DFE3E8',
}
