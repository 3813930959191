import PropTypes from 'prop-types'
import {useNavigate} from '@reach/router'
import SaveIcon from '@mui/icons-material/Save'
import * as resources from '../../../../../constants/resources'
import {generateResourcePath} from '../../../../../constants/routes'
import {useOneResource, useUpdateResource} from '../../../hooks/api'
import {useAlert} from '../../../hooks/useAlert'
import {FormSection, ScreenHeader} from '../../visual'
import Button from '../../containers/Button/Button'
import WidgetContainer from '../../containers/Widget/WidgetContainer'
import Widget from '../../containers/Widget/Widget'
import NotFound from '../NotFound'
import ClientsForm from './ClientsForm'


export const queryDef = ({clientId}) => ({
  app: '',
  resource: resources.CLIENTS,
  id: clientId,
})

const layoutName = 'clientsLayout'

const ClientsEdit = ({clientId}) => {
  const navigate = useNavigate()
  const [client] = useOneResource(queryDef({clientId}))
  const updateClient = useUpdateResource(null, resources.CLIENTS, clientId)
  const showAlert = useAlert()

  if (!client || client.deletedAt) return <NotFound />

  const handleSubmit = async (values) => {
    await updateClient.mutateAsync(values)
    navigate(generateResourcePath(resources.CLIENTS))
    showAlert(`Odoberateľ ${clientId} uložený`, 'success')
    // Still need to handle errors somehow
  }

  return (
    <>
      <ClientsForm onSubmit={handleSubmit} initialValues={client}>
        {({submitting, form, handleSubmit}) => (
          <>
            <ScreenHeader title={`Odoberateľ ${clientId}`}>
              <Button
                  icon={<SaveIcon />}
                  variant="contained"
                  disabled={submitting}
                  onClick={handleSubmit}
              >
                Uložiť
              </Button>
            </ScreenHeader>
            <WidgetContainer name={layoutName} disableDrag disableResize>
              <Widget key="manufacture" data-grid={{w: 2, h: 8, x: 0, y: 0}}>
                <FormSection title="Odoberateľ">
                  {form}
                </FormSection>
              </Widget>
            </WidgetContainer>
          </>
        )}
      </ClientsForm>
    </>
  )
}

ClientsEdit.propTypes = {
  clientId: PropTypes.string,
}

export default ClientsEdit
