import PropTypes from 'prop-types'
import {makeStyles} from '@mui/styles'
import Button from '../../containers/Button/Button'


const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    minHeight: '100%',
    paddingTop: '10%',
  },
  icon: {
    fontSize: 200,
  },
  button: {
    marginTop: 25,
  },
}))

const ErrorScreen = ({Icon, title, buttonLabel, onClick, children}) => {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <Icon className={classes.icon} />
      <h1>{title}</h1>
      {children}
      <Button onClick={onClick} className={classes.button}>{buttonLabel}</Button>
    </div>
  )
}

ErrorScreen.propTypes = {
  Icon: PropTypes.element.isRequired,
  title: PropTypes.string,
  buttonLabel: PropTypes.string,
  onClick: PropTypes.func,
  children: PropTypes.node,
}

export default ErrorScreen
