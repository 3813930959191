import {makeStyles} from '@mui/styles'
import PropTypes from 'prop-types'


const useStyles = makeStyles((theme) => ({
  root: {
    'display': 'flex',
    'justifyContent': 'space-between',
    'alignItems': ({center}) => center ? 'center' : 'flex-start',
    'margin': `0 -${theme.spacing(2)}`,
    '& > *': {
      flexGrow: 1,
      flexShrink: 1,
      flexBasis: 0,
      padding: `${theme.spacing(2)}`,
    },

    [theme.breakpoints.down('sm')]: {
      'flexDirection': 'column',
      '& > *': {
        width: '100%',
        padding: `0 ${theme.spacing(2)} 0`,
      },
    },
  },
}))

const Columns = ({children, center}) => {
  const classes = useStyles({center})

  return (
    <div className={classes.root}>
      {children}
    </div>
  )
}

Columns.propTypes = {
  center: PropTypes.node,
  children: PropTypes.node.isRequired,
}

export default Columns
