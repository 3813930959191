import PropTypes from 'prop-types'
import MuiLink from '@mui/material/Link'


export const override = {}

const Link = ({children, ...props}) => (
  <MuiLink underline="none" color="primary" {...props}>
    {children}
  </MuiLink>
)

Link.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Link
