import Avatar from '@mui/material/Avatar'
import * as resources from '../../../../../../constants/resources'
import {generateResourcePath} from '../../../../../../constants/routes'


const imageCell = () => {
  const renderImageCell = ({value, row}) => {
    const {file} = row.original
    const url = generateResourcePath('api', resources.IMAGES, value)

    if (!url) return value

    return (
      <Avatar src={url} alt={file.filename} />
    )
  }
  return renderImageCell
}

export default imageCell
