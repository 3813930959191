import {useState} from 'react'
import {If} from 'babel-plugin-jsx-control-statements'
import * as resources from '../../../../../constants/resources'
import {useDeleteManyResources, useListResource} from '../../../hooks/api'
import useLocalSetting from '../../../hooks/useLocalSetting'
import {useAlert} from '../../../hooks/useAlert'
import {ScreenHeader} from '../../visual'
import DeleteButton from '../../containers/DeleteButton/DeleteButton'
import SearchBoxInput from '../../form/SearchBoxInput/SearchBoxInput'
import Filters from '../../containers/Filters/Filters'
import RolesTable from './RolesTable'
import RolesCreate from './RolesCreate'
import RolesEdit from './RolesEdit'


export const queryDef = ({query} = {}) => ({
  app: '',
  resource: resources.ROLES,
  query,
})

const initialQuery = {
  page: 0,
  pageSize: 10,
  filter: {deleted: false},
}

const queryName = 'rolesQuery'

const RolesList = () => {
  const [selectedIds, setSelectedIds] = useState([])
  const [query, setQuery] = useLocalSetting(queryName, initialQuery)
  const {data: roles, total, refetch, isFetching} = useListResource(queryDef({query}))
  const deleteRoles = useDeleteManyResources(null, resources.ROLES)
  const [roleId, setRoleId] = useState(null)
  const showAlert = useAlert()

  const handleDelete = async () => {
    await deleteRoles.mutateAsync(selectedIds)

    setSelectedIds([])
    await refetch()
    showAlert('Vymazané', 'success')
  }

  return (
    <>
      <ScreenHeader title="Role">
        <DeleteButton
            onDelete={handleDelete}
            selectedIds={selectedIds}
            disabled={isFetching}
            data-test-id="deleteButton"
        />
        <RolesCreate refetch={refetch} />
      </ScreenHeader>
      <Filters query={query} setQuery={setQuery}>
        <SearchBoxInput fullWidth={false} name="q" label="Vyhľadať rolu" />
      </Filters>
      <RolesTable
          roles={roles}
          total={total}
          query={query}
          setQuery={setQuery}
          selectedIds={selectedIds}
          setSelectedIds={setSelectedIds}
          setRoleId={setRoleId}
      />
      <If condition={roleId}>
        <RolesEdit
            setRoleId={setRoleId}
            roleId={roleId}
            refetch={refetch}
        />
      </If>
    </>
  )
}

export default RolesList
