import S from 'fluent-json-schema'
import {map, compact} from 'lodash'
import * as baseSchemas from '../../baseSchemas'
import {client} from '../../schemas'
import {ALL_ATTRIBUTES} from './attributes'
import {ALL_CONTENT} from './content'


export const image = {
  id: baseSchemas.uuid().required(),
  alt: baseSchemas.standardString().required(),
}

export const orderItemAttribute = {
  value: S.anyOf(map(ALL_ATTRIBUTES, 'value')).required(),
  attributeId: baseSchemas.id().required(),
  orderItemAttributeAttachmentId: baseSchemas.nullable(baseSchemas.uuid()),
}

export const attribute = {
  name: baseSchemas.standardString().required(),
  type: baseSchemas.attributeType().required(),
  variable: S.boolean().default(false),
  meta: S.anyOf(map(ALL_ATTRIBUTES, 'meta')).required(),
  // Extra
  id: baseSchemas.id(),
}

export const productAttribute = {
  value: S.anyOf(map(ALL_ATTRIBUTES, 'value')).required(),
}

export const content = {
  type: baseSchemas.contentType().required(),
  value: S.anyOf(compact(map(ALL_CONTENT, 'value'))).required().default({}),
  children: S.array().items(S.object()).raw({nullable: true}),
}

export const category = {
  name: baseSchemas.standardString().required(),
  categoryId: baseSchemas.nullable(baseSchemas.id()),
  attributes: baseSchemas.array(baseSchemas.optionalObject(attribute)),
  slug: baseSchemas.standardString().required(),
  order: S.integer(),
  seoTitle: baseSchemas.standardString(),
  seoDescription: S.string(),
  seoRobots: baseSchemas.nullableEnum(baseSchemas.seoRobotsType()),
  disabled: S.boolean().default(false),
  enableFilters: S.boolean().default(false),
  images: baseSchemas.nullable(baseSchemas.collection(image)),
  content: baseSchemas.nullable(baseSchemas.object(content)),
  showInNavigation: S.boolean().default(true),
  transparent: S.boolean().default(false),
}

export const orderItem = {
  quantity: baseSchemas.positiveInteger().required(),
  productId: baseSchemas.id().required(),
  orderItemAttributes: baseSchemas.collection(orderItemAttribute),
}

export const product = {
  name: baseSchemas.standardString().required(),
  code: baseSchemas.standardString().required(),
  categoryId: baseSchemas.id().required(),
  attributes: baseSchemas.array(baseSchemas.optionalObject(attribute).extend(baseSchemas.object(productAttribute))),
  slug: baseSchemas.standardString().required(),
  order: S.integer(),
  seoTitle: baseSchemas.standardString(),
  seoDescription: S.string(),
  seoRobots: baseSchemas.nullableEnum(baseSchemas.seoRobotsType()),
  disabled: S.boolean().default(false),
  images: baseSchemas.nullable(baseSchemas.collection(image)),
  content: baseSchemas.nullable(baseSchemas.object(content)),
  app: baseSchemas.nullableEnum(baseSchemas.productApp()),
}

export const order = {
  state: baseSchemas.nullableEnum(baseSchemas.orderStateType()),
  notes: baseSchemas.standardString(),
  userId: baseSchemas.id(),
  orderItems: baseSchemas.collection(orderItem),
}

export const clientUser = {
  email: baseSchemas.email().required(),
  password: baseSchemas.standardString().required(),
  clientId: baseSchemas.id().required(),
}

export const page = {
  title: baseSchemas.standardString(),
  slug: baseSchemas.standardString().required(),
  seoTitle: baseSchemas.standardString(),
  seoDescription: S.string(),
  seoNoIndex: S.boolean(),
  content: baseSchemas.nullable(baseSchemas.object(content)),
}

export const user = {
  email: baseSchemas.email().required(),
  firstName: baseSchemas.standardString().required(),
  lastName: baseSchemas.standardString(),
  companyRole: baseSchemas.standardString(),
  phone: baseSchemas.standardString(),
  showAllOrders: S.boolean().default(false),
  clientId: baseSchemas.id().required(),
}

export const profile = {
  password: baseSchemas.minimalPassword().required(),
  oldPassword: baseSchemas.standardString(),
  firstName: baseSchemas.standardString().required(),
  lastName: baseSchemas.standardString(),
  companyRole: baseSchemas.standardString(),
  phone: baseSchemas.standardString(),
  client: baseSchemas.optionalObject({
    name: client.name,
    address1: client.address1,
    address2: client.address2,
    city: client.city,
    zipCode: client.zipCode,
    country: client.country,
    companyType: client.companyType,
    industrySector: client.industrySector,
    ico: client.ico,
    dic: client.dic,
    icDph: client.icDph,
  }),
}

export const registration = {
  email: baseSchemas.email().required(),
  password: baseSchemas.minimalPassword().required(),
  firstName: baseSchemas.standardString().required(),
  lastName: baseSchemas.standardString().required(),
  companyRole: baseSchemas.standardString(),
  phone: baseSchemas.standardString(),
  client: baseSchemas.object({
    name: client.name,
    address1: client.address1,
    address2: client.address2,
    city: client.city,
    zipCode: client.zipCode,
    country: client.country,
    companyType: client.companyType,
    industrySector: client.industrySector,
    ico: client.ico,
    dic: client.dic,
    icDph: client.icDph,
  }, {}),
}

export const passwordChange = {
  token: baseSchemas.standardString().required(),
  password: baseSchemas.minimalPassword().required(),
}
