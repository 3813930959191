import PropTypes from 'prop-types'
import MuiList from '@mui/material/List'


export const override = {}


const List = ({children, ...props}) => (
  <MuiList {...props}>
    {children}
  </MuiList>
)

List.propTypes = {
  children: PropTypes.node.isRequired,
}

export default List
