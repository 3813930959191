/**
 * Values in `permissions.role` column:
 */
export const MANAGE_USERS = 'MANAGE_USERS'
export const MANAGE_SYSTEM_SETTINGS = 'MANAGE_SYSTEM_SETTINGS'
export const MANAGE_CLIENTS = 'MANAGE_CLIENTS'
export const MANAGE_SEALS = 'MANAGE_SEALS'
export const MANAGE_CALCULATIONS = 'MANAGE_CALCULATIONS'
export const MANAGE_MANUFACTURES = 'MANAGE_MANUFACTURES'
export const MANAGE_MATERIALS = 'MANAGE_MATERIALS'
export const MANAGE_BLANKS = 'MANAGE_BLANKS'
export const MANAGE_ACCESSORIES = 'MANAGE_ACCESSORIES'
export const MANAGE_EXPEDITIONS = 'MANAGE_EXPEDITIONS'
export const MANAGE_PRODUCTS = 'MANAGE_PRODUCTS'
export const MANAGE_ORDERS = 'MANAGE_ORDERS'

export const ALL_PERMISSIONS = [MANAGE_USERS, MANAGE_SYSTEM_SETTINGS, MANAGE_CLIENTS,
  MANAGE_SEALS, MANAGE_CALCULATIONS, MANAGE_MANUFACTURES, MANAGE_MATERIALS, MANAGE_BLANKS, MANAGE_ACCESSORIES,
  MANAGE_EXPEDITIONS, MANAGE_PRODUCTS, MANAGE_ORDERS]
