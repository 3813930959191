import PropTypes from 'prop-types'
import {makeStyles} from '@mui/styles'
import {Link} from '@reach/router'


const useStyles = makeStyles((theme) => ({
  root: {
    'marginTop': theme.spacing(1),
    'marginRight': theme.spacing(2),

    [theme.breakpoints.down('sm')]: {
      marginRight: theme.spacing(1),
    },

    '& img': {
      'width': '1.5rem',

      [theme.breakpoints.down('sm')]: {
        width: '1.125rem',
      },
      '&:hover': {
        opacity: '0.85',
      },
    },
  },
}))

const AppsSwitcherIcon = ({children, to, ...props}) => {
  const classes = useStyles()

  return (
    <Link className={classes.root} to={to} {...props}>
      {children}
    </Link>
  )
}

AppsSwitcherIcon.propTypes = {
  to: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
}

export default AppsSwitcherIcon
