import PropTypes from 'prop-types'
import Drawer from '../mui/Drawer'
import ContentContainer from '../ContentContainer/ContentContainer'


const Navigation = ({children, open, icon, ...props}) => (
  <ContentContainer>
    <Drawer open={open} icon={icon} {...props}>
      {children}
    </Drawer>
  </ContentContainer>
)

Navigation.propTypes = {
  open: PropTypes.bool,
  icon: PropTypes.node,
  children: PropTypes.node.isRequired,
}

export default Navigation
