import PropTypes from 'prop-types'
import MuiInputAdornment from '@mui/material/InputAdornment'


export const override = {}

const InputAdornment = ({children, ...props}) => (
  <MuiInputAdornment position="end" {...props}>
    {children}
  </MuiInputAdornment>
)

InputAdornment.propTypes = {
  children: PropTypes.node.isRequired,
}

export default InputAdornment
