import {useTransition} from 'react'
import PropTypes from 'prop-types'
import BaseButton from '../../visual/BaseButton/BaseButton'


const Button = ({children, variant, color, disabled, timeoutMs, onClick, ...props}) => {
  const [isPending, startTransition] = useTransition({
    timeoutMs,
  })

  const handleClick = (e) => {
    startTransition(() => {
      onClick(e)
    })
  }

  return (
    <BaseButton
        disabled={disabled}
        isPending={isPending}
        onClick={onClick && handleClick}
        variant={variant}
        color={color}
        {...props}
    >
      {children}
    </BaseButton>
  )
}

Button.propTypes = {
  timeoutMs: PropTypes.number,
  disabled: PropTypes.bool,
  isPending: PropTypes.bool,
  onClick: PropTypes.func,
  variant: PropTypes.string,
  color: PropTypes.string,
  children: PropTypes.node.isRequired,
}

Button.defaultProps = {
  timeoutMs: 3000,
  disabled: false,
  variant: 'outlined',
  color: 'primary',
}

export default Button
