import PropTypes from 'prop-types'
import MuiSelect from '@mui/material/Select'


export const override = {}


const Select = ({children, ...props}) => (
  <MuiSelect variant="outlined" {...props}>
    {children}
  </MuiSelect>
)

Select.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Select
