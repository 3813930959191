import PropTypes from 'prop-types'
import MuiBackdrop from '@mui/material/Backdrop'


export const override = {
  styleOverrides: {
    root: {
      color: '#fff',
    },
  },
}

const Backdrop = ({children, ...props}) => (
  <MuiBackdrop {...props}>
    {children}
  </MuiBackdrop>
)

Backdrop.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Backdrop
