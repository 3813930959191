import PropTypes from 'prop-types'
import {Suspense} from 'react'
import {Router as ReachRouter} from '@reach/router'
import {AppContainer, Footer} from '../../visual'
import Loading from '../../visual/Loading/Loading'


const Screen = ({children, ...props}) => (
  <Suspense fallback={<Loading />}>
    <AppContainer {...props}>
      <ReachRouter>
        {children}
      </ReachRouter>
      {/* eslint-disable-next-line jsx-control-statements/jsx-jcs-no-undef */}
      <Footer version={BUILD_ID} date={BUILD_DATE} changelog={config.changelog} />
    </AppContainer>
  </Suspense>
)

Screen.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Screen
