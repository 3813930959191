import {useState} from 'react'
import * as resources from '../../../../../constants/resources'
import * as relations from '../../../../../constants/relations'
import {imageMimeTypes} from '../../../../../constants/fileTypes'
import relation from '../../../../lib/relation'
import {useDeleteManyResources, useListResource} from '../../../hooks/api'
import useLocalSetting from '../../../hooks/useLocalSetting'
import {useAlert} from '../../../hooks/useAlert'
import {ScreenHeader} from '../../visual'
import DeleteButton from '../../containers/DeleteButton/DeleteButton'
import FileUpload from '../../containers/FileUpload/FileUpload'
import SearchBoxInput from '../../form/SearchBoxInput/SearchBoxInput'
import Filters from '../../containers/Filters/Filters'
import MediaLibraryTable from './MediaLibraryTable'


const allowedImageTypes = Array.from(imageMimeTypes)

export const queryDef = ({query} = {}) => ({
  app: '',
  resource: resources.IMAGES,
  query: {
    ...query,
  },
})

const initialQuery = {
  page: 0,
  pageSize: 10,
  filter: {deleted: false},
  sort: ['createdAt', 'DESC'],
  expand: relation(relations.FILE),
}

const queryName = 'mediaLibraryQuery'

const MediaLibraryList = () => {
  const [selectedIds, setSelectedIds] = useState([])
  const [query, setQuery] = useLocalSetting(queryName, initialQuery)
  const {data: images, total, refetch, isFetching} = useListResource(queryDef({query}))
  const deleteImages = useDeleteManyResources('', resources.IMAGES)
  const showAlert = useAlert()

  const handleDelete = async () => {
    await deleteImages.mutateAsync(selectedIds)

    setSelectedIds([])
    await refetch()
    showAlert('Vymazané', 'success')
  }

  return (
    <>
      <ScreenHeader title="Obrázky">
        <DeleteButton
            onDelete={handleDelete}
            selectedIds={selectedIds}
            disabled={isFetching}
            data-test-id="deleteButton"
        />
        <FileUpload multiple app={null} route={resources.IMAGES} accept={allowedImageTypes} onUpload={() => refetch()}>
          Pridať obrázky
        </FileUpload>
      </ScreenHeader>
      <Filters query={query} setQuery={setQuery}>
        <SearchBoxInput fullWidth={false} name="q" label="Vyhľadať obrázok" />
      </Filters>
      <MediaLibraryTable
          images={images}
          total={total}
          query={query}
          setQuery={setQuery}
          selectedIds={selectedIds}
          setSelectedIds={setSelectedIds}
      />
    </>
  )
}

export default MediaLibraryList
