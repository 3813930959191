import PropTypes from 'prop-types'
import MuiAppBar from '@mui/material/AppBar'


export const override = {}


const AppBar = ({children, ...props}) => (
  <MuiAppBar position="fixed" {...props}>
    {children}
  </MuiAppBar>
)

AppBar.propTypes = {
  children: PropTypes.node.isRequired,
}

export default AppBar
