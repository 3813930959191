import PropTypes from 'prop-types'
import MuiDialog from '@mui/material/Dialog'


export const override = {}


const Dialog = ({children, ...props}) => (
  <MuiDialog {...props}>
    {children}
  </MuiDialog>
)

Dialog.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Dialog
