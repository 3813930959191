import PropTypes from 'prop-types'
import MuiSwitch from '../mui/Switch'
import FormControl from '../mui/FormControl'
import FormControlLabel from '../mui/FormControlLabel'
import FormHelperText from '../mui/FormHelperText'


const Switch = ({label, onChange, helperText, fullWidth, error, required, checked, ...props}) => {
  return (
    <FormControl
        fullWidth={fullWidth}
        error={error}
        required={required}
    >
      <FormControlLabel
          label={label ? label : null}
          control={
            <MuiSwitch
                onChange={onChange}
                checked={checked}
                {...props}
            />
          }
      />
      <FormHelperText>{helperText}</FormHelperText>
    </FormControl>
  )
}

Switch.propTypes = {
  label: PropTypes.string,
  onChange: PropTypes.func,
  helperText: PropTypes.string,
  fullWidth: PropTypes.bool,
  error: PropTypes.bool,
  required: PropTypes.bool,
  checked: PropTypes.bool,
}

export default Switch
