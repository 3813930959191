import PropTypes from 'prop-types'
import {makeStyles} from '@mui/styles'
import Paper from '../mui/Paper'


const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
    marginBottom: theme.spacing(2),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',

    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  },
  filters: {
    'display': 'flex',
    'alignItems': 'center',
    'justifyContent': 'flex-start',
    'flexWrap': 'wrap',
    'flexGrow': 1,
    'maxWidth': `calc(100% - ${theme.spacing(2) * 3}px)`,
    '& > *': {
      marginRight: theme.spacing(2),

      [theme.breakpoints.down('sm')]: {
        marginRight: theme.spacing(0),
      },
    },

    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  },
  loading: {
    flexShrink: 0,
  },
}))

const FiltersContainer = ({children, loading, ...props}) => {
  const classes = useStyles()

  return (
    <Paper className={classes.root} {...props}>
      <div className={classes.filters}>
        {children}
      </div>
      <div className={classes.loading}>
        {loading}
      </div>
    </Paper>
  )
}

FiltersContainer.propTypes = {
  loading: PropTypes.node,
  children: PropTypes.node,
}

export default FiltersContainer
