import PropTypes from 'prop-types'
import MuiTableCell from '@mui/material/TableCell'


export const override = {
  styleOverrides: {
    root: {
      borderBottomColor: '#DFE3E8',
    },
  },
}

const TableCell = ({children, ...props}) => (
  <MuiTableCell {...props}>
    {children}
  </MuiTableCell>
)

TableCell.propTypes = {
  children: PropTypes.node,
}

export default TableCell
