import PropTypes from 'prop-types'
import Dialog from '../mui/Dialog/Dialog'
import DialogTitle from '../mui/Dialog/DialogTitle'
import DialogContent from '../mui/Dialog/DialogContent'
import DialogActions from '../mui/Dialog/DialogActions'
import DialogContentText from '../mui/Dialog/DialogContentText'


const Modal = ({open, onClose, children, ...props}) => (
  <Dialog data-test-id="modal" open={open} onClose={onClose} {...props}>
    {children}
  </Dialog>
)

Modal.Title = DialogTitle
Modal.Content = DialogContent
Modal.ContentText = DialogContentText
Modal.Actions = DialogActions

Modal.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  children: PropTypes.node.isRequired,
}

export default Modal
