import PropTypes from 'prop-types'
import MuiCollapse from '@mui/material/Collapse'


export const override = {}


const Collapse = ({children, ...props}) => (
  <MuiCollapse {...props}>
    {children}
  </MuiCollapse>
)

Collapse.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Collapse
