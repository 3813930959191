import PropTypes from 'prop-types'
import {makeStyles} from '@mui/styles'
import {If} from 'babel-plugin-jsx-control-statements'
import FormControl from '../mui/FormControl'
import InputLabel from '../mui/InputLabel'
import Input from '../mui/Input'
import FormHelperText from '../mui/FormHelperText'


const useStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      margin: theme.spacing(1),
    },
  },
  inputLabel: {
    position: 'unset',
    marginBottom: theme.spacing(1),
    transform: 'none',
    fontSize: '12px',
  },
  input: {
    display: 'none',
  },
}))

const FileInput = ({
  label, onChange, helperText, fullWidth, error, required, accept, multiple,
  children, ...props
}) => {
  const classes = useStyles()

  return (
    <FormControl
        className={classes.root}
        fullWidth={fullWidth}
        error={error}
        required={required}
    >
      <label>
        <InputLabel className={classes.inputLabel} component="span" shrink={false}>{label}</InputLabel>
        <Input
            className={classes.input}
            type="file"
            onChange={onChange}
            accept={accept}
            multiple={multiple}
            {...props}
        />
        {children}
      </label>
      <If condition={helperText}>
        <FormHelperText>{helperText}</FormHelperText>
      </If>
    </FormControl>
  )
}

FileInput.propTypes = {
  label: PropTypes.string,
  onChange: PropTypes.func,
  helperText: PropTypes.string,
  fullWidth: PropTypes.bool,
  error: PropTypes.bool,
  required: PropTypes.bool,
  accept: PropTypes.string,
  multiple: PropTypes.bool,
  children: PropTypes.node,
}

export default FileInput
