import {useState} from 'react'


const useControlledValue = (value, onChange) => {
  const [controlledValue, setControlledValue] = useState(value)

  const controlledOnChange = (event) => {
    const value = (event && event.target ? event.target.value : event)
    if (value !== undefined) setControlledValue(value)
    if (onChange) onChange(event)
  }

  return {controlledValue, controlledOnChange}
}

export default useControlledValue
