export const MEGASEAL = 'megaseal'
export const MEGASHOP = 'megashop'
export const MEGAGASKET = 'megagasket'
export const MEGACON = 'megacon'
export const MEGAPRESS = 'megapress'
export const MEGABELT = 'megabelt'
export const EXPEDITION = 'expedition'

export const ALL_APPS = [
  MEGASEAL,
  MEGASHOP,
  MEGAGASKET,
  MEGACON,
  MEGAPRESS,
  MEGABELT,
  EXPEDITION,
]
