import PropTypes from 'prop-types'
import MuiBox from '@mui/material/Box'


export const override = {
  // Empty
}


const Box = ({children, ...props}) => (
  <MuiBox {...props}>
    {children}
  </MuiBox>
)

Box.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Box
