import PropTypes from 'prop-types'
import MuiChip from '@mui/material/Chip'


export const override = {
  styleOverrides: {
    root: {
      margin: '2px',
    },
  },
}


const Chip = ({children, ...props}) => (
  <MuiChip {...props}>
    {children}
  </MuiChip>
)

Chip.propTypes = {
  children: PropTypes.node,
}

export default Chip
