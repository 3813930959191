import PropTypes from 'prop-types'
import MuiToolbar from '@mui/material/Toolbar'


export const override = {
  styleOverrides: {
    root: {
      minHeight: '57px',
    },
  },
}

const Toolbar = ({children, ...props}) => (
  <MuiToolbar {...props}>
    {children}
  </MuiToolbar>
)

Toolbar.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Toolbar
