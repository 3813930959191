import {reduce, sortBy} from 'lodash'


const findClosest = (collection, value) => (
  reduce(
    sortBy(collection),
    (prev, curr) => (Math.abs(curr - value) < Math.abs(prev - value)) ? curr : prev,
  )
)

export default findClosest
