import PropTypes from 'prop-types'
import MuiListItemIcon from '@mui/material/ListItemIcon'


export const override = {}


const ListItemIcon = ({children, ...props}) => (
  <MuiListItemIcon {...props}>
    {children}
  </MuiListItemIcon>
)

ListItemIcon.propTypes = {
  children: PropTypes.node.isRequired,
}

export default ListItemIcon
