import PropTypes from 'prop-types'
import {isEmpty} from 'lodash'
import {isRequired, showErrorOnBlur, showErrorOnChange, withForm} from 'common-fe/forms'
import * as resources from '../../../../../constants/resources'
import * as baseSchemas from '../../../../common/baseSchemas'
import * as schemas from '../../../../common/schemas'
import {useAsyncUniqueValidate} from '../../../hooks/useAsyncUniqueValidate'
import WidgetContainer from '../../containers/Widget/WidgetContainer'
import Widget from '../../containers/Widget/Widget'
import {FormSection} from '../../visual'
import TextInput from '../../form/TextInput/TextInput'
import PasswordInput from '../../form/PasswordInput/PasswordInput'
import ReferenceInput from '../../form/ReferenceInput/ReferenceInput'
import EnumInput from '../../form/EnumInput/EnumInput'


const layoutName = 'usersLayout'

const UsersForm = ({initialValues}) => {
  const isNewRecord = !initialValues?.id
  const validateEmail = useAsyncUniqueValidate({
    app: null,
    resource: resources.USERS,
    column: 'email',
    deleted: true,
  })
  return (
    <WidgetContainer name={layoutName} disableDrag disableResize>
      <Widget key="users" data-grid={{w: 2, h: 10, x: 0, y: 0}}>
        <FormSection title="Používateľ">
          <TextInput name="firstName" label="Meno" required={isRequired(schemas.user.firstName)} />
          <TextInput name="lastName" label="Priezvisko" required={isRequired(schemas.user.lastName)} />
          <TextInput
              name="email"
              label="Email"
              required={isRequired(schemas.user.email)}
              fieldProps={{validate: validateEmail}}
              showError={showErrorOnBlur}
              readOnly={!isNewRecord}
          />
          <PasswordInput
              name="password"
              label="Heslo"
              autoComplete="off"
              helperText={!isNewRecord ? 'Heslo vyplňte len ak ho chcete zmeniť' : ''}
              showError={showErrorOnChange}
              fieldProps={{
                validate: (password, {confirmPassword}) => {
                  if (isNewRecord && isEmpty(password)) return 'musí obsahovať požadovanú položku password'
                  if (password !== confirmPassword) return 'Heslá sa musia zhodovať'
                },
              }}
              required={isNewRecord ? isRequired(schemas.user.password) : false}
          />
          <PasswordInput
              name="confirmPassword"
              label="Nové heslo znova"
              showError={showErrorOnChange}
              fieldProps={{
                validate: (confirmPassword, {password}) => {
                  if (password !== confirmPassword) return 'Heslá sa musia zhodovať'
                },
              }}
              required={isNewRecord ? isRequired(schemas.user.password) : false}
          />
          <ReferenceInput
              name="roleId"
              label="Rola"
              optionText="name"
              resource={resources.ROLES}
              defaultValue={initialValues?.role}
              required={isRequired(schemas.user.roleId)}
          />
          <EnumInput
              name="apps"
              label="Aplikácie"
              enumName="apps"
              required={isRequired(schemas.user.apps)}
              multiple
          />
        </FormSection>
      </Widget>
    </WidgetContainer>
  )
}

UsersForm.propTypes = {
  initialValues: PropTypes.object,
}

const schema = baseSchemas.object({
  firstName: schemas.user.firstName,
  lastName: schemas.user.lastName,
  email: schemas.user.email,
  password: baseSchemas.optional(schemas.user.password),
  roleId: schemas.user.roleId,
  apps: schemas.user.apps,
})

export default withForm({schema})(UsersForm)
