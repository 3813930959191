import PropTypes from 'prop-types'
import {useField} from 'react-final-form'
import {showErrorOnChange} from 'common-fe/forms'
import {Choose, When, Otherwise, For} from 'babel-plugin-jsx-control-statements'
import {map, keys, mapValues, castArray} from 'lodash'
import {Clear as ClearIcon} from '@mui/icons-material'
import allEnums from '../../../constants/enums'
import {Chip, Select, StaticField} from '../../visual'
import IconButton from '../../visual/mui/IconButton'


const enumsOptions = mapValues(allEnums, (enums) => map(enums, (label, value) => ({label, value})))

const EnumInput = ({
  readOnly, name, label, required, clearable, helperText, fullWidth, showError = showErrorOnChange, fieldProps, multiple,
  enumName, filter, ...props
}) => {
  const {input: {value, onChange, ...restInput}, meta} = useField(name, fieldProps)
  const {isError, helperTextOrError} = showError({meta, helperText})

  const options = enumsOptions[enumName].filter(filter)
  const format = (value) => {
    if (!value) return value
    if (!multiple) return filter(value) ? value : null
    return value.filter(filter)
  }
  const handleChange = (e) => {
    const {value} = e.target
    onChange(format(value))
  }
  const handleClear = () => {
    onChange(null)
  }
  return (
    <Choose>
      <When condition={!readOnly}>
        <Select
            key={value || ''}
            fullWidth={fullWidth}
            options={options}
            multiple={multiple}
            value={format(value)}
            onChange={handleChange}
            required={required}
            name={name}
            label={label}
            helperText={helperTextOrError}
            error={isError}
            inputProps={{required, ...restInput}}
            endAdornment={
              clearable && value && (
                <IconButton marginRight onClick={() => handleClear()}>
                  <ClearIcon />
                </IconButton>
              )
            }
            {...props}
        />
      </When>
      <Otherwise>
        <StaticField label={label}>
          <For each="item" of={castArray(value)} index="idx">
            <Chip key={idx} label={allEnums[enumName][item]} />
          </For>
        </StaticField>
      </Otherwise>
    </Choose>
  )
}

EnumInput.defaultProps = {
  fullWidth: true,
  filter: (option) => option,
}

EnumInput.propTypes = {
  readOnly: PropTypes.bool,
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  clearable: PropTypes.string,
  required: PropTypes.bool,
  helperText: PropTypes.string,
  fullWidth: PropTypes.bool,
  showError: PropTypes.func,
  fieldProps: PropTypes.object,
  multiple: PropTypes.bool,
  enumName: PropTypes.oneOf(keys(allEnums)),
  filter: PropTypes.func,
}

export default EnumInput
