import PropTypes from 'prop-types'
import {For} from 'babel-plugin-jsx-control-statements'
import {Table, TableBody} from '../../visual'
import DatagridHead from './DatagridHead'
import DatagridRow from './DatagridRow'


const DatagridTable = ({
  selectedIds, tableProps, tableBodyProps, prepareRow, headerGroups, rows, rowStyles, columnProps, onRowClick,
  ...props
}) => {
  return (
    <Table {...tableProps} {...props}>
      <DatagridHead headerGroups={headerGroups} />
      <TableBody {...tableBodyProps}>
        <For each="row" of={rows}>
          <DatagridRow
              key={row.id}
              row={row}
              selectedIds={selectedIds}
              rowStyles={rowStyles}
              columnProps={columnProps}
              prepareRow={prepareRow}
              onClick={onRowClick}
          />
        </For>
      </TableBody>
    </Table>
  )
}

DatagridTable.propTypes = {
  selectedIds: PropTypes.arrayOf(PropTypes.number),
  tableProps: PropTypes.object.isRequired,
  tableBodyProps: PropTypes.object.isRequired,
  prepareRow: PropTypes.func.isRequired,
  headerGroups: PropTypes.array.isRequired,
  rows: PropTypes.array.isRequired,
  rowStyles: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
  columnProps: PropTypes.object,
  onRowClick: PropTypes.func,
}

export default DatagridTable
