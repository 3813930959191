import PropTypes from 'prop-types'
import {SnackbarProvider, useSnackbar} from 'notistack'


export const AlertsProvider = ({children}) => (
  <SnackbarProvider
      anchorOrigin={{horizontal: 'right', vertical: 'bottom'}}
  >
    {children}
  </SnackbarProvider>
)

AlertsProvider.propTypes = {
  children: PropTypes.node,
}

export const useAlert = () => {
  const {enqueueSnackbar} = useSnackbar()

  return (message, variant = 'default') => {
    return enqueueSnackbar(message, {
      variant,
    })
  }
}
