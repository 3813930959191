import {useNavigate} from '@reach/router'
import SaveIcon from '@mui/icons-material/Save'
import * as resources from '../../../../../constants/resources'
import generatePath from '../../../../lib/generatePath'
import {useCreateResource} from '../../../hooks/api'
import {useAlert} from '../../../hooks/useAlert'
import Button from '../../containers/Button/Button'
import {ScreenHeader, FormSection} from '../../visual'
import WidgetContainer from '../../containers/Widget/WidgetContainer'
import Widget from '../../containers/Widget/Widget'
import ClientsForm from './ClientsForm'


const layoutName = 'clientsLayout'

const ClientsEdit = () => {
  const navigate = useNavigate()
  const createClient = useCreateResource(null, resources.CLIENTS)
  const showAlert = useAlert()

  const handleSubmit = async (values) => {
    const {id} = await createClient.mutateAsync(values)

    navigate(generatePath('/clients/:clientId', {clientId: id}))
    showAlert('Vytvorené', 'success')
    // Still need to handle errors somehow
  }

  return (
    <>
      <ClientsForm onSubmit={handleSubmit}>
        {({submitting, form, handleSubmit}) => (
          <>
            <ScreenHeader title="Vytvoriť odoberateľa">
              <Button icon={<SaveIcon />} variant="contained" disabled={submitting} onClick={handleSubmit}>
                Uložiť
              </Button>
            </ScreenHeader>
            <WidgetContainer name={layoutName} disableDrag disableResize>
              <Widget key="manufacture" data-grid={{w: 2, h: 8, x: 0, y: 0}}>
                <FormSection title="Odoberateľ">
                  {form}
                </FormSection>
              </Widget>
            </WidgetContainer>
          </>
        )}
      </ClientsForm>
    </>
  )
}

export default ClientsEdit
