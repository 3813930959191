import {useEffect, useMemo} from 'react'
import {isEmpty, map} from 'lodash'
import PropTypes from 'prop-types'
import {useSortBy, useTable, usePagination} from 'react-table'
import {If} from 'babel-plugin-jsx-control-statements'
import {TableContainer, TablePagination} from '../../visual'
import rowSelect from './rowSelect'
import DatagridTable from './DatagridTable'


const defaultPageIndex = 0
const defaultPageSize = 10
const getDefaultAllIds = (data) => map(data, 'id')

const ControlledDatagrid = ({
  data, columns, total, rowStyles, query, setQuery, disablePagination, disableSortBy, columnProps,
  onRowClick, pageOptions, allIds, selectedIds, setSelectedIds, ...props
}) => {
  const {
    getTableProps, getTableBodyProps, headerGroups, prepareRow, rows,
    setPageSize, state: {pageIndex, pageSize, sortBy},
  } = useTable(
    {
      data,
      columns,
      useControlledState: (state) => {
        return useMemo(
          () => ({
            ...state,
            allIds: allIds || getDefaultAllIds(data),
            selectedIds,
            setSelectedIds,
            pageIndex: query?.page || defaultPageIndex,
          }),
          // Rule incorrectly enumerates used references
          // eslint-disable-next-line react-hooks/exhaustive-deps
          [state, selectedIds, setSelectedIds, total, query]
        )
      },
      initialState: {
        pageIndex: query?.page || defaultPageIndex,
        sortBy: query?.sort ? [{id: query.sort[0], desc: query.sort[1] === 'DESC'}] : [],
        pageSize: query?.pageSize || defaultPageSize,
      },
      manualPagination: true,
      manualSortBy: true,
      disableMultiSort: true,
      pageCount: data.total,
      disableSortBy: !isEmpty(selectedIds) || disableSortBy,
      ...props,
    },
    useSortBy,
    usePagination,
    (hooks) => {
      if (selectedIds) hooks.visibleColumns.push(rowSelect)
    }
  )

  useEffect(() => {
    if (setQuery) {
      const sortItem = sortBy?.[0]
      const sortDirection = sortItem?.desc ? 'DESC' : 'ASC'

      const sort = () => {
        if (sortItem?.id) {
          return {sort: [sortItem?.id, sortDirection]}
        }

        return {sort: undefined}
      }

      const pagination = () => {
        if (disablePagination) return {}

        return {pageSize}
      }

      setQuery({...pagination(), ...sort(), page: 0})
    }
  }, // eslint-disable-next-line react-hooks/exhaustive-deps
  [pageSize, sortBy])

  return (
    <TableContainer>
      <DatagridTable
          selectedIds={selectedIds}
          tableProps={getTableProps()}
          tableBodyProps={getTableBodyProps()}
          prepareRow={prepareRow}
          headerGroups={headerGroups}
          rows={rows}
          rowStyles={rowStyles}
          columnProps={columnProps}
          onRowClick={onRowClick}
      />
      <If condition={!disablePagination}>
        <TablePagination
            backIconButtonProps={
              !isEmpty(selectedIds) ? {
                disabled: true,
              } : undefined
            }
            nextIconButtonProps={
              !isEmpty(selectedIds) ? {
                disabled: true,
              } : undefined
            }
            SelectProps={!isEmpty(selectedIds) ? {
              disabled: true,
            } : undefined}
            rowsPerPage={pageSize}
            page={pageIndex}
            count={total}
            rowsPerPageOptions={pageOptions}
            onRowsPerPageChange={(e) => setPageSize(e.target.value)}
            onPageChange={(_e, page) => setQuery({page})}
        />
      </If>
    </TableContainer>
  )
}

ControlledDatagrid.defaultProps = {
  pageOptions: [5, 10, 25],
  disablePagination: false,
  disableSortBy: false,
}

ControlledDatagrid.propTypes = {
  data: PropTypes.array.isRequired,
  columns: PropTypes.array.isRequired,
  total: PropTypes.number,
  query: PropTypes.object,
  setQuery: PropTypes.func.isRequired,
  rowStyles: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
  columnProps: PropTypes.object,
  disablePagination: PropTypes.bool,
  disableSortBy: PropTypes.bool,
  pageOptions: PropTypes.arrayOf(PropTypes.number),
  allIds: PropTypes.arrayOf(PropTypes.number),
  selectedIds: PropTypes.arrayOf(PropTypes.number),
  setSelectedIds: PropTypes.func,
  onRowClick: PropTypes.func,
}

export default ControlledDatagrid
