import PropTypes from 'prop-types'
import {makeStyles} from '@mui/styles'
import ListItem from '../mui/ListItem'
import ListItemText from '../mui/ListItemText'
import ListItemIcon from '../mui/ListItemIcon'


const useStyles = makeStyles((theme) => ({
  ListItemIcon: {
    color: `${theme.palette.common.white}`,
  },
  root: {
    color: `${theme.palette.common.white}`,
    textDecoration: 'none',
  },
}))

const NavigationItem = ({children, icon, ...props}) => {
  const classes = useStyles()

  return (
    <ListItem button className={classes.root} {...props}>
      <ListItemIcon className={classes.ListItemIcon}>
        {icon}
      </ListItemIcon>
      <ListItemText>
        {children}
      </ListItemText>
    </ListItem>
  )
}

NavigationItem.propTypes = {
  icon: PropTypes.node,
  children: PropTypes.node.isRequired,
}

export default NavigationItem
