import PropTypes from 'prop-types'
import {makeStyles} from '@mui/styles'


const useStyles = makeStyles((theme) => ({
  container: {
    'display': 'grid',
    'gridTemplateColumns': 'repeat( auto-fit, minmax(300px, 1fr) )',
    'gap': '32px',

    '& a': {
      display: 'block',
    },

    [theme.breakpoints.down('sm')]: {
      justifyItems: 'center',
      gridTemplateColumns: 'repeat( auto-fit, minmax(200px, 1fr) )',
    },
  },
}))

const PaperButton = ({children}) => {
  const classes = useStyles()

  return (
    <div className={classes.container}>
      {children}
    </div>
  )
}

PaperButton.propTypes = {
  children: PropTypes.node.isRequired,
}

export default PaperButton
