export const NEW = 'new'
export const ACCEPTED = 'accepted'
export const SENT = 'sent'
export const COMPLETED = 'completed'
export const CLOSED = 'closed'

export const ALL_ORDER_STATE_TYPES = [
  NEW,
  ACCEPTED,
  SENT,
  COMPLETED,
  CLOSED,
]
