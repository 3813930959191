import PropTypes from 'prop-types'
import MuiTextField from '@mui/material/TextField'


export const override = {}

const TextField = ({className, label, multiline, InputProps, ...props}) => (
  <MuiTextField
      variant="outlined"
      className={className}
      label={label}
      multiline={multiline}
      InputProps={InputProps}
      {...props}
  />
)

TextField.propTypes = {
  className: PropTypes.string,
  label: PropTypes.string,
  multiline: PropTypes.bool,
  InputProps: PropTypes.object,
}

export default TextField
