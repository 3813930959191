import {isRequired, withForm} from 'common-fe/forms'
import * as resources from '../../../../../constants/resources'
import * as baseSchemas from '../../../../common/baseSchemas'
import * as schemas from '../../../../common/schemas'
import {useAsyncUniqueValidate} from '../../../hooks/useAsyncUniqueValidate'
import TextInput from '../../form/TextInput/TextInput'
import {Columns} from '../../visual'


const ClientsForm = () => {
  const validateName = useAsyncUniqueValidate({
    app: null,
    resource: resources.CLIENTS,
    column: 'name',
  })
  return (
    <Columns>
      <div>
        <TextInput
            name="name"
            label="Meno"
            required={isRequired(schemas.client.name)}
            fieldProps={{validate: validateName}}
        />
        <TextInput name="address1" label="Adresa 1" required={isRequired(schemas.client.address1)} />
        <TextInput name="address2" label="Adresa 2" required={isRequired(schemas.client.address2)} />
        <TextInput name="city" label="Mesto" required={isRequired(schemas.client.city)} />
        <TextInput name="zipCode" label="PSČ" required={isRequired(schemas.client.zipCode)} />
      </div>
      <div>
        <TextInput name="country" label="Krajina" required={isRequired(schemas.client.country)} />
        <TextInput name="ico" label="IČO" required={isRequired(schemas.client.ico)} />
        <TextInput name="dic" label="DIČ" required={isRequired(schemas.client.dic)} />
        <TextInput name="icDph" label="DIČ DPH" required={isRequired(schemas.client.icDph)} />
        <TextInput name="discount" label="Zľava" required={isRequired(schemas.client.discount)} />
      </div>
    </Columns>
  )
}

const schema = baseSchemas.object(schemas.client)

export default withForm({schema})(ClientsForm)
