import {FORM_ERROR} from 'final-form'
import {isError} from 'lodash'
import {useAuth} from '../../../hooks/auth'
import {Login as VisualLogin} from '../../visual'
import LoginForm from './LoginForm'


const Login = () => {
  const {login} = useAuth()

  const handleLogin = async (values) => {
    const {email, password, remember} = values
    const res = await login({email, password, remember})

    if (isError(res)) {
      return {[FORM_ERROR]: 'Nesprávny email alebo heslo'}
    }
  }

  return (
    <VisualLogin>
      <LoginForm onSubmit={handleLogin} />
    </VisualLogin>
  )
}

export default Login
