import MuiLinearProgress from '@mui/material/LinearProgress'


export const override = {
  styleOverrides: {
    root: {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
    },
  },
}

const LinearProgress = ({...props}) => {
  return (
    <MuiLinearProgress {...props} />
  )
}

export default LinearProgress
