import PropTypes from 'prop-types'
import {makeStyles} from '@mui/styles'


const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    overflow: 'auto',
    width: '100%',
    padding: `100px ${theme.spacing(2)} ${theme.spacing(3)} ${theme.spacing(4)}`,

    [theme.breakpoints.down('sm')]: {
      padding: `100px ${theme.spacing(2)} ${theme.spacing(3)} 100px`,
    },
  },
}))

const AppContainer = ({children, ...props}) => {
  const classes = useStyles()

  return (
    <main className={classes.root} {...props}>
      {children}
    </main>
  )
}

AppContainer.propTypes = {
  children: PropTypes.node.isRequired,
}

export default AppContainer
