import PropTypes from 'prop-types'
import MuiDialogContentText from '@mui/material/DialogContentText'


export const override = {
  // Empty
}

const DialogContentText = ({children, ...props}) => (
  <MuiDialogContentText {...props}>
    {children}
  </MuiDialogContentText>
)

DialogContentText.propTypes = {
  children: PropTypes.node.isRequired,
}

export default DialogContentText
