import {format} from 'date-fns'


const dateCell = () => {
  const renderDateCell = ({value}) => {
    if (!value) return '–'

    return format(new Date(value), 'dd.MM.yyyy, HH:mm')
  }
  return renderDateCell
}

export default dateCell
