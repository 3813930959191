import {isFunction, startsWith} from 'lodash'
import useLocalStorage from './useLocalStorage'


const prefix = 'setting_'

const useLocalSetting = (name, initialValue = {}) => {
  const [value, setValue] = useLocalStorage(prefix + name, initialValue)

  const setSetting = (newValue) => {
    if (isFunction(newValue)) {
      return setValue((prevSetting) => ({...prevSetting, ...newValue(value)}))
    }

    return setValue((prevSetting) => ({...prevSetting, ...newValue}))
  }

  return [value, setSetting]
}

export const clearAllSettings = () => {
  for (const key in window.localStorage) {
    if (startsWith(key, prefix)) window.localStorage.removeItem(key)
  }
}

export default useLocalSetting
