import {createHistory} from '@reach/router'
import * as Sentry from '@sentry/react'
import {Integrations} from '@sentry/tracing'


const history = createHistory(window)

if (config.sentry) {
  Sentry.init({
    dsn: config.sentry,
    // eslint-disable-next-line jsx-control-statements/jsx-jcs-no-undef
    release: `megabelt@${BUILD_ID}`,
    environment: 'production',
    integrations: [
      new Integrations.BrowserTracing({
        tracingOrigins: ['localhost:5000', 'srv.megabelt.sk', 'dev.srv.megabelt.sk'],
        routingInstrumentation: Sentry.reactRouterV5Instrumentation(history),
      }),
      new Sentry.Replay({
        sessionSampleRate: 0.1,
        errorSampleRate: 1.0,
        maskAllText: false,
      }),
    ],
    tracesSampleRate: 1.0,
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
  })
}

export default Sentry
