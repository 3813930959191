import S from 'fluent-json-schema'
import {mapValues, round, omit, reduce} from 'lodash'
import {ALL_ATTRIBUTE_TYPES} from 'common/constants/megashop/megashopAttributeTypes'
import {ALL_IMAGE_SIZE_TYPES} from '../../constants/megashop/megashopImageSizes'
import {ALL_DIMENSIONS} from '../../constants/megaseal/megasealDimensions'
import {ALL_COMPONENT_TYPES} from '../../constants/megaseal/megasealComponentTypes'
import {ALL_APPS} from '../../constants/apps'
import {BASE64_RATIO} from '../../constants/numeric'
import {ALL_SCHEDULE_FREQUENCIES} from '../../constants/scheduleFrequency'
import {ALL_CONTENT_TYPES} from '../../constants/contentTypes'
import {ALL_ORDER_STATE_TYPES} from '../../constants/megashop/megashopOrderStates'
import {ALL_PRODUCT_APPS} from '../../constants/megashop/megashopProductApps'
import {ALL_SEO_ROBOTS_TYPES} from '../../constants/megashop/megashopSeoRobotsTypes'


export const id = () => S.integer().minimum(1).raw({example: 1})

export const uuid = () => S.string().pattern(/^[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}$/).format('uuid')

export const timestamp = () => S.integer().minimum(1).raw({example: 1561161600})

export const standardString = () => S.string().maxLength(255)

export const email = () => S.string().format(S.FORMATS.EMAIL).raw({example: 'admin@admin.com'})

export const dimension = () => S.string().enum(ALL_DIMENSIONS)

export const app = () => S.string().enum(ALL_APPS)
export const productApp = () => S.string().enum(ALL_PRODUCT_APPS)

export const componentType = () => S.string().enum(ALL_COMPONENT_TYPES)

export const scheduleFrequencies = () => S.string().enum(ALL_SCHEDULE_FREQUENCIES)

export const positiveInteger = () => S.integer().minimum(1)

export const color = () => S.string().pattern(/^#[a-f0-9]{6}$/)

export const file = () => {
  // eslint-disable-next-line jsx-control-statements/jsx-jcs-no-undef
  if (typeof window === 'undefined' && (typeof isNextWebpack === 'undefined' || !isNextWebpack)) {
    const config = require('../../../../config').default
    return S.string().maxLength(round(BASE64_RATIO * config.api.maxFileSize)).contentEncoding('base64')
  } else {
    // eslint-disable-next-line jsx-control-statements/jsx-jcs-no-undef
    return S.string().maxLength(round(BASE64_RATIO * config.api.maxFileSize)).contentEncoding('base64')
  }
}

export const minimalPassword = () => {
  // eslint-disable-next-line jsx-control-statements/jsx-jcs-no-undef
  if (typeof window === 'undefined' && (typeof isNextWebpack === 'undefined' || !isNextWebpack)) {
    const config = require('../../../../config').default
    return S.string().minLength(config.api.megashop.passwordMinLength)
  } else {
    // eslint-disable-next-line jsx-control-statements/jsx-jcs-no-undef
    return S.string().minLength(config.api.megashop.passwordMinLength)
  }
}

export const object = (keys, defaultValue = undefined) => reduce(
  keys,
  (schema, value, key) => schema.prop(key, value),
  S.object().additionalProperties(false).default(defaultValue)
)

/* eslint-disable-next-line no-underscore-dangle */
export const optional = (schema) => S.raw({...schema._getState(), required: []})

/* eslint-disable-next-line no-underscore-dangle */
export const nullable = (schema) => S.raw({...schema._getState(), nullable: true})

export const nullableEnum = (schema) => (
  S.raw({
    ...schema._getState(), // eslint-disable-line no-underscore-dangle
    enum: [...schema._getState().enum, null], // eslint-disable-line no-underscore-dangle
    nullable: true,
  })
)

export const optionalObject = (keys) => optional(object(keys))

export const nand = (scheme, key1, key2) => S.oneOf([
  object(omit(scheme, key1)),
  object(omit(scheme, key2)),
])

export const allOptional = (schemas) => mapValues(schemas, (schema) => schema.raw({required: false}))

export const array = (schema) => S.array().items(schema)

export const collection = (schema) => S.array().items(object(schema))

// Megashop specific
export const attributeType = () => S.string().enum(ALL_ATTRIBUTE_TYPES)
export const contentType = () => S.string().enum(ALL_CONTENT_TYPES)
export const orderStateType = () => S.string().enum(ALL_ORDER_STATE_TYPES)
export const imageSizeType = () => S.string().enum(ALL_IMAGE_SIZE_TYPES)
export const seoRobotsType = () => S.string().enum(ALL_SEO_ROBOTS_TYPES)
