import S from 'fluent-json-schema'
import * as megasealSystemSettingNames from '../../../../constants/megaseal/megasealSystemSettings'
import {MEGASEAL} from '../../../../constants/apps'
import * as baseSchemas from '../../baseSchemas'


/**
 * Entries in `system_setting` table:
 */
export const MEGASEAL_PRICE_PER_MINUTE = {
  name: megasealSystemSettingNames.MEGASEAL_PRICE_PER_MINUTE,
  schema: S.number().exclusiveMinimum(0).default(0.79),
  public: false,
  app: MEGASEAL,
}

export const MEGASEAL_PREPARATION_TIME = {
  name: megasealSystemSettingNames.MEGASEAL_PREPARATION_TIME,
  schema: S.number().exclusiveMinimum(0).default(6),
  public: true,
  app: MEGASEAL,
}

export const MEGASEAL_MANUFACTURE_TIMES = {
  name: megasealSystemSettingNames.MEGASEAL_MANUFACTURE_TIMES,
  schema: S.array().minItems(1).items(baseSchemas.object({
    minWidth: S.integer().minimum(0).default(0),
    value: S.number().exclusiveMinimum(0).default(1),
  })).default([{minWidth: 0, value: 0}]),
  public: true,
  app: MEGASEAL,
}

export const MEGASEAL_WASTE_DIFFERENCE_HARD = {
  name: megasealSystemSettingNames.MEGASEAL_WASTE_DIFFERENCE_HARD,
  schema: S.number().exclusiveMinimum(0).default(2.6),
  public: true,
  app: MEGASEAL,
}

export const MEGASEAL_WASTE_DIFFERENCE_FLOPPY = {
  name: megasealSystemSettingNames.MEGASEAL_WASTE_DIFFERENCE_FLOPPY,
  schema: S.number().exclusiveMinimum(0).default(1.35),
  public: true,
  app: MEGASEAL,
}

export const MEGASEAL_MANUFACTURE_NOTICE = {
  name: megasealSystemSettingNames.MEGASEAL_MANUFACTURE_NOTICE,
  schema: baseSchemas.object({
    recipient: baseSchemas.email().required(),
    content: baseSchemas.object({
      body: baseSchemas.standardString().required(),
      subject: baseSchemas.standardString().required(),
    }),
  }).raw({nullable: true}).default(null),
  public: false,
  app: MEGASEAL,
}

export const MEGASEAL_LABELER_NOTICE = {
  name: megasealSystemSettingNames.MEGASEAL_LABEL_NOTICE,
  schema: baseSchemas.object({
    recipient: baseSchemas.email().required(),
    content: baseSchemas.object({
      body: baseSchemas.standardString().required(),
      subject: baseSchemas.standardString().required(),
    }),
  }).raw({nullable: true}).default(null),
  public: false,
  app: MEGASEAL,
}

export const MEGASEAL_INSPECTOR_NOTICE = {
  name: megasealSystemSettingNames.MEGASEAL_INSPECTION_NOTICE,
  schema: baseSchemas.object({
    recipient: baseSchemas.email().required(),
    content: baseSchemas.object({
      body: baseSchemas.standardString().required(),
      subject: baseSchemas.standardString().required(),
    }),
  }).raw({nullable: true}).default(null),
  public: false,
  app: MEGASEAL,
}

export const MEGASEAL_STOREKEEPER_NOTICE = {
  name: megasealSystemSettingNames.MEGASEAL_TRANSFER_NOTICE,
  schema: baseSchemas.object({
    recipient: baseSchemas.email().required(),
    content: baseSchemas.object({
      body: baseSchemas.standardString().required(),
      subject: baseSchemas.standardString().required(),
    }),
  }).raw({nullable: true}).default(null),
  public: false,
  app: MEGASEAL,
}

export const MEGASEAL_DONE_NOTICE = {
  name: megasealSystemSettingNames.MEGASEAL_DONE_NOTICE,
  schema: baseSchemas.object({
    recipient: baseSchemas.email().required(),
    content: baseSchemas.object({
      body: baseSchemas.standardString().required(),
      subject: baseSchemas.standardString().required(),
    }),
  }).raw({nullable: true}).default(null),
  public: false,
  app: MEGASEAL,
}

export const MEGASEAL_CHUCK_SIZES = {
  name: megasealSystemSettingNames.MEGASEAL_CHUCK_SIZES,
  schema: S.array().items(baseSchemas.positiveInteger()),
  public: true,
  app: MEGASEAL,
}

export const ALL_MEGASEAL_SYSTEM_SETTINGS = [
  MEGASEAL_PRICE_PER_MINUTE,
  MEGASEAL_PREPARATION_TIME,
  MEGASEAL_MANUFACTURE_TIMES,
  MEGASEAL_WASTE_DIFFERENCE_HARD,
  MEGASEAL_WASTE_DIFFERENCE_FLOPPY,
  MEGASEAL_MANUFACTURE_NOTICE,
  MEGASEAL_LABELER_NOTICE,
  MEGASEAL_INSPECTOR_NOTICE,
  MEGASEAL_STOREKEEPER_NOTICE,
  MEGASEAL_DONE_NOTICE,
  MEGASEAL_CHUCK_SIZES,
]
