import PropTypes from 'prop-types'
import {For} from 'babel-plugin-jsx-control-statements'
import {TableCellHeader, TableHead, TableRow, TableSortLabel} from '../../visual'


const DatagridHead = ({headerGroups, ...props}) => (
  <TableHead {...props}>
    <For each="headerGroup" of={headerGroups} index="idx">
      <TableRow {...headerGroup.getHeaderGroupProps()}>
        <For each="column" of={headerGroup.headers}>
          <TableCellHeader
              padding={column.checkbox ? 'checkbox' : 'default'}
              {...column.getHeaderProps(column.getSortByToggleProps())}
          >
            <TableSortLabel active={column.isSorted} direction={column.isSortedDesc ? 'desc' : 'asc'}>
              {column.render('Header')}
            </TableSortLabel>
          </TableCellHeader>
        </For>
      </TableRow>
    </For>
  </TableHead>
)

DatagridHead.propTypes = {
  headerGroups: PropTypes.array.isRequired,
}

export default DatagridHead
