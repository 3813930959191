import {Component, createContext, useContext, useEffect, useState, useCallback, useMemo} from 'react'
import PropTypes from 'prop-types'
import {isAfter} from 'date-fns'
import * as routes from '../../../constants/routes'
import {AUTHENTICATION_ERROR} from '../../../constants/errorCodes'
import Sentry from '../sentry'
import {api} from '../utils/api'
import Loading from '../components/screens/Loading'
import useIsMounted from './useIsMounted'
import {useAlert} from './useAlert'


const AuthContext = createContext()
const SessionContext = createContext()
const STORAGE_KEY = 'session'


const getStorageObject = (storage, key) => {
  const val = storage.getItem(key)
  if (val === null) return undefined
  try {
    return JSON.parse(val)
  } catch (e) {
    storage.removeItem(key) // Prevent permanently broken app
    throw e // But still throw as this is unhandled exception
  }
}

const setStorageObject = (storage, key, value) => {
  if (!value) return storage.removeItem(key)
  return storage.setItem(key, JSON.stringify(value))
}

/**
 * Tries to copy copy session to sessionStorage if opened in different tab.
 */
const initSession = () => {
  const session = getStorageObject(window.localStorage, STORAGE_KEY)
  if (!session) return setStorageObject(window.sessionStorage, STORAGE_KEY, null)
  if (session.validUntil || getStorageObject(window.sessionStorage, STORAGE_KEY)) return

  setStorageObject(window.sessionStorage, STORAGE_KEY, session)
}

export const readSession = () => {
  const session = getStorageObject(window.localStorage, 'session')
  if (!session) return null

  const validUntil = session.validUntil && new Date(session.validUntil)
  if (!validUntil && !getStorageObject(window.sessionStorage, 'session')) return null
  if (validUntil && !isAfter(validUntil, new Date())) return null

  return {
    ...session,
    validUntil,
  }
}

const removeSession = () => {
  setStorageObject(window.localStorage, STORAGE_KEY, null)
  setStorageObject(window.sessionStorage, STORAGE_KEY, null)
  if (config.sentry) Sentry.configureScope((scope) => scope.setUser(null))
}

const writeSession = (session) => {
  if (!session) return removeSession()

  setStorageObject(window.localStorage, STORAGE_KEY, session)
  if (!session.validUntil) setStorageObject(window.sessionStorage, STORAGE_KEY, session)
  if (config.sentry) Sentry.setUser({id: session.userId})
}

/**
 * This error boundary will catch errors and if logged out error is encountered,
 * will call onError function passed as prop.
 */
class ErrorBoundary extends Component {
  static propTypes = {
    onError: PropTypes.func.isRequired,
    children: PropTypes.node,
  }

  componentDidCatch(error, _errorInfo) {
    if (error?.data?.errorCode === AUTHENTICATION_ERROR) {
      this.props.onError()
      return
    }
    throw error
  }

  render() {
    return this.props.children
  }
}

export const AuthProvider = ({children}) => {
  const [isInitialized, setInitialized] = useState(false)
  const [session, setSession] = useState(null)
  const isMounted = useIsMounted()
  const showAlert = useAlert()

  const handleError = () => {
    showAlert('Boli ste odhlásený', 'error')
    writeSession(null)
    if (isMounted.current) setSession(null)
  }

  const refreshAuth = useCallback(async () => {
    const currentSession = readSession()
    let newSession = null
    if (currentSession) {
      try {
        newSession = await api('POST', routes.API_LOGIN, {sessionToken: currentSession.token})
      } catch (e) {
        newSession = null
      }
    }

    writeSession(newSession)
    if (isMounted.current) setSession(newSession)
    if (isMounted.current) setInitialized(true)
  }, [isMounted])

  const login = useCallback(async (data) => {
    let newSession
    try {
      newSession = await api('POST', routes.API_LOGIN, {data})
      writeSession(newSession)
      if (isMounted.current) setSession(newSession)
      return newSession
    } catch (e) {
      newSession = null
      writeSession(newSession)
      if (isMounted.current) setSession(newSession)
      return e
    }
  }, [isMounted])

  const logout = useCallback(async () => {
    if (session) {
      try {
        await api('POST', routes.API_LOGOUT, {sessionToken: session.token})
      } catch (e) {
        window.console.error(e)
      }
    }
    writeSession(null)
    if (isMounted.current) setSession(null)
  }, [isMounted, session])

  useEffect(() => {
    initSession()
    refreshAuth()
  }, [refreshAuth])

  const utils = useMemo(() => ({
    refreshAuth,
    login,
    logout,
  }), [refreshAuth, login, logout])

  if (!isInitialized) return <Loading />

  return (
    <AuthContext.Provider value={utils}>
      <SessionContext.Provider value={session}>
        <ErrorBoundary onError={handleError}>
          {children}
        </ErrorBoundary>
      </SessionContext.Provider>
    </AuthContext.Provider>
  )
}

AuthProvider.propTypes = {
  children: PropTypes.node,
}

export const useSession = () => {
  const session = useContext(SessionContext)
  return session
}

export const useAuth = () => {
  const {refreshAuth, login, logout} = useContext(AuthContext)
  return {
    refreshAuth,
    login,
    logout,
  }
}
